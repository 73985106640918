import React from 'react';
import { Fab } from '@material-ui/core';
import { useAktivJarat } from '../data';
import { PlayArrow, LinkedCamera, Add } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useDrawer } from '../hooks';
import config from '../config.json';

export function FuvarAction({ f, style }) {
  const history = useHistory();
  const { select, add, added, jarat } = useAktivJarat();
  const { openDrawer } = useDrawer();


  if (!f || f.lemondva || !f.sajat) return null;  

  let lezarva = (f.tetel.filter(t => t.sajat && (!t.eteny || (!t.iteny && t.jelleg !== 6))).length === 0);
  console.log("fuvar", f,jarat);

  if (lezarva) {
    return <Fab color="secondary" onClick={openDrawer('send', { id: 'feltoltes', jarat: f.pos })} style={style}><LinkedCamera /> </Fab>
  }
  const start = async () => {
    await select(f.pos);
    history.push('/');
  }

  const play = <Fab color="secondary" onClick={start} style={style}><PlayArrow /> </Fab>;
  
  if (jarat && config.multijarat && 
    (!added || !added.includes(f.pos)) &&
    f.pos !== jarat.pos){
      const addJarat = async () => {
        await add(f.pos);
        history.push('/');
      }  
      return <>
        {play}
        <Fab style={{
          position: 'fixed',
          bottom: 24,
          right: 24,
          zIndex: 100}} color="primary" onClick={addJarat}><Add /> </Fab>
        </>
  }

  return play;
}