import React, { useState, useEffect, createContext, useContext } from 'react';

const WindowDimensionsCtx = createContext(null);
export const WindowDimensionsProvider = ({ children }) => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    return () => { window.removeEventListener('resize', handleResize) }
  }, [])
  return (
    <WindowDimensionsCtx.Provider value={dimensions}>
      {children}
    </WindowDimensionsCtx.Provider>
  )
}


export const mobileBreakpoint = 720;

export const useWindowDimensions = () => useContext(WindowDimensionsCtx)
export const useIsMobile = () => {
  const { width } = useWindowDimensions();
  return width < mobileBreakpoint;
}
export const ResponsiveLayout = ({ breakpoint, renderMobile, renderDesktop}) => {
  const { width } = useWindowDimensions();
  return <React.Fragment>{(width > (breakpoint || mobileBreakpoint) ? renderDesktop : renderMobile)}</React.Fragment>
}

