import React from 'react'
import { FastField } from 'formik'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import FormHelperText from '@material-ui/core/FormHelperText';

const renderToggle = (
  { field: { value,name }, form, calculate, options, size, exclusive, buttonProps, ...other }
) => {
  const error = form.errors[name];
  const handleChange = (ev, value) => {
    console.log(value);
    form.setFieldValue(name, value);
  }

  return <><ToggleButtonGroup size={size || "medium"} exclusive={exclusive} value={exclusive ? [value] : value} onChange={handleChange} {...other}>
    {options.map(({ value, label }) => <ToggleButton key={value} {...buttonProps} value={value}>{label}</ToggleButton>)}    
  </ToggleButtonGroup>
  <FormHelperText error={true}>{error}</FormHelperText>
  </>
}

export const ToggleField = (props) => {
  return <FastField
    component={renderToggle}
    {...props}
  />
}