import React, { useState } from 'react';
import { Typography, Button, TextField } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { Formik, Form, useFormikContext } from 'formik';
import { useValue, useDispatch } from '../data';
import { SulyInput, MennyisegInput, PotkocsiInput, HofokInput, PotInfo } from './Input';
import { TextField2 } from '../rf/input/Text';


function LekasztGomb() {
  const { setFieldValue, submitForm } = useFormikContext();
  const ok = (hol) => () => {
    setFieldValue('leakaszt', hol);
    submitForm();
  }
  return <>
    <Button onClick={ok('mashol')}>Máshol</Button>
    <Button onClick={ok('itt')} variant="contained" type="submit" name="leakaszt" color="primary">Itt hagytam</Button>
  </>
}

export function FelakasztasDlg({ close }) {
  const dispatch = useDispatch('potkocsi');
  const current = useValue('potkocsi').frsz;
  const [info, setInfo] = useState(false);


  const onSubmit = async ({ potkocsi, leakaszt, suly, mennyiseg, hofok, leakasztastxt }) => {
    if (potkocsi) {
      potkocsi = potkocsi.toUpperCase().replace(/[^A-Z0-9]/g, '');
      if (current) await dispatch('leakaszt', { hol: leakaszt, txt: leakasztastxt });
      await dispatch('felakaszt', { frsz: potkocsi, suly, mennyiseg, hofok });
      close();
    }
  }

  return (
    <Formik initialValues={{ potkocsi: '', leakaszt: 'itt',leakasztastxt:'' }} validateOnChange onSubmit={onSubmit}>
      <Form>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh' }}>
          <DialogContent>
            <PotkocsiInput setInfo={setInfo} />
            <PotInfo info={info} />
            <SulyInput />
            <MennyisegInput />
            <HofokInput info={info} />
            {current && <TextField2 multiline rows={2} placeholder={`Ha szükséges adja meg, hogy pontosan hol hagyta a ${current} pótkocsit`} name="leakasztastxt" />}
          </DialogContent>
          {current && <Typography variant="body2" style={{ padding: 16, textAlign: 'center'}}>A <b>{current}</b> pótkocsit hol akasztotta le?</Typography>}
          <DialogActions>
            <Button onClick={close} >Mégsem</Button>
            <div style={{ flex: '1 0 0' }} />
            {current ? <LekasztGomb /> : <Button variant="contained" type="submit" name="leakaszt" color="primary">Felakasztás</Button>}
          </DialogActions>
        </div>
      </Form>
    </Formik>);
}




export function LeakasztasDlg({ close }) {
  const pot = useValue('potkocsi').frsz;
  const [txt, setTxt] = useState('');
  const dispatch = useDispatch('potkocsi');


  const itt = () => {
    dispatch('leakaszt', { hol: 'itt', txt });
    close();
  };
  const mashol = () => {
    dispatch('leakaszt', { hol: 'mashol', txt });
    close();
  }

  return (<>
    <DialogContent style={{ textAlign: 'center' }}>
      <h2>{pot}</h2>pótkocsi leakasztása
      <br /><br />
      <TextField name="txt" value={txt} onChange={(ev) => setTxt(ev.target.value)} fullWidth placeholder="Ha szükséges adja meg, hogy pontosan hol hagyta a pótkocsit" variant="filled" multiline rows={3} />

    </DialogContent>
    <DialogActions>
      <Button onClick={close} >Mégsem</Button>
      <div style={{ flex: '1 0 0' }} />
      <Button onClick={mashol}>Máshol</Button>
      <Button variant="contained" onClick={itt} color="primary">Itt hagytam</Button>
    </DialogActions>
  </>);
}



export function LeakasztasHelyeDlg({ close }) {
  const pot = useValue('potkocsi').frsz;
  const [txt, setTxt] = useState('');

  const dispatch = useDispatch('potkocsi');


  const send = () => {
    dispatch('leakaszt', { hol: 'mashol', txt });
    close();
  };

  return (<>
    <DialogContent style={{ textAlign: 'center' }}>
      <p>Adja meg, hogy hol hagyta a {pot} pótkocsit</p>
      <TextField name="txt" value={txt} autoFocus onChange={(ev) => setTxt(ev.target.value)} fullWidth placeholder="" variant="filled" multiline rows={3} />
    </DialogContent>
    <DialogActions>
      <Button onClick={close} >Mégsem</Button>
      <div style={{ flex: '1 0 0' }} />
      <Button onClick={send}>Leakasztás jelzése</Button>
    </DialogActions>
  </>);
}

