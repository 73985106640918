import React, { useState } from 'react';
import { Button, LinearProgress,ListItem,ListItemText,ListItemIcon,DialogContent,DialogActions} from '@material-ui/core';
import { useDispatch, useValue, useAktivJarat } from '../data';
import { ErrorBoundary } from '../app/ErrorBoundary';
import { AssignmentTurnedIn, Assignment } from '@material-ui/icons';
import { useDialog } from '../rf/Dialog';

export function JKonyvDlg({open: {jkonyv}, close}) {
  console.log("JKOnyv",jkonyv);
  let [idx,setIdx] = useState(0);
  let send = useDispatch('msg');
  let {jarat} = useAktivJarat();

  const set = v =>{
    jkonyv[idx].ertek = v;
    if(idx === jkonyv.length-1){
      let sum = jkonyv.reduce((acc, v) => {
        acc[v.ertek] = (acc[v.ertek] || 0) + 1;
        return acc;
      }, {});
      sum = Object.entries(sum).map(([k, v]) => `${v} x ${k}`).join(', ');

      send('send',{
        msg: `${jkonyv[0].urlap}: ${sum}`,
        rnev: 'jegyzokonyv',
        jarat: jarat && jarat.pos,
        ertek: jkonyv[0].urlap+'\n'+jkonyv.map(v=>`${v.nev}|${v.ertek}`).join('\n')
      });
      close();

    }else{
      setIdx(idx+1);
    }
  }

  let v = jkonyv[idx];
  let b = v.ertekek.split('\n').map((b,i)=><Button key={i} variant="outlined" onClick={()=>set(b)}>{b}</Button>);
  
  return <ErrorBoundary component="JegyzokonyvWizzard">
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center',alignItems:'center', height: '100vh' }}>
    <DialogContent style={{width:'100%'}}>
      <h2>{jkonyv[idx].urlap}</h2>
    <LinearProgress variant="determinate" value={idx*100/jkonyv.length} />
      <div >

      </div>
      <div style={{height:250, alignItems:'center',display:'flex'}}><div>{v.cimke}</div></div>
      <div style={{display:'flex',justifyContent:'center'}}>
        {b}
      </div> 
    </DialogContent>
    <DialogActions>
      <Button onClick={close} >Kilép</Button>
    </DialogActions>
    </div>
  </ErrorBoundary>
}

export function UrlapDlg({close }) {
  const urlap = useValue('urlap');
  const { setDialog } = useDialog();
  if(!urlap) return null;

  const start = u=> {
    setDialog({id:'jkonyv', jkonyv: urlap[u].map(m => ({...m}))});
  }
    
  return  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center',alignItems:'center', height: '100vh' }}>
  <DialogContent style={{textAlign:'center'}}>
    {Object.entries(urlap).map(([k,v])=> <Button style={{margin:10,padding:16}} key={k} variant='outlined' startIcon={<AssignmentTurnedIn/>} onClick={()=>start(k)}>{v[0].urlap}</Button>)}
  </DialogContent>
  <DialogActions>
    <Button onClick={close} >Mégsem</Button>
  </DialogActions>
</div>

}


export function JKonyvGomb({u,checked}) {
  const urlap = useValue('urlap');
  const { setDialog } = useDialog();
  if(!urlap || !urlap[u] || !urlap[u].length) return null;
  console.log("Űrlap",urlap,u,urlap[u],);
    
  return <ListItem onClick={()=>setDialog({id:'jkonyv', jkonyv: urlap[u].map(m => ({...m}))})}>
    <ListItemIcon>{checked? <AssignmentTurnedIn /> : <Assignment />}</ListItemIcon>
    <ListItemText primary={urlap[u][0].urlap} secondary="ide kattintva beküldheti" /></ListItem>
}