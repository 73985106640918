import React from 'react';
//import { useJSON } from '../../hooks';

import MUISelect from '@material-ui/core/Select';
import MUINativeSelect from '@material-ui/core/NativeSelect';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

export const Select = (props) => {
  let { field, form, calculate, label, ...other } = props;
  if (!field.value) field.value = '';
  const onBlur = (ev) => {
    field.onBlur(ev); //tovább adjuk
    if (calculate) calculate(field.name, ev.target.value);
  }
  const error = form.errors[field.name];
  return <FormControl fullWidth margin="normal">
    <InputLabel>{label}</InputLabel>
    <MUISelect
      {...field}
      {...other}
      error={!!error}
      onBlur={onBlur}
      helperText={error}
      InputLabelProps={other.InputLabelProps || {}}>
      <MenuItem value="">
        <em></em>
      </MenuItem>
      {props.options.map(o => <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>)}
    </MUISelect>
    {error && <FormHelperText  error={true}>{error}</FormHelperText>}
  </FormControl>
}



export const NativeSelect = (props) => {
  let { field, form, calculate, label,InputLabelProps,helperText, ...other } = props;
  if (!field.value) field.value = '';
  const onBlur = (ev) => {
    field.onBlur(ev); //tovább adjuk
    if (calculate) calculate(field.name, ev.target.value);
  }
  const error = form.errors[field.name];
  return <FormControl fullWidth margin="normal">
    <InputLabel>{label}</InputLabel>
    <MUINativeSelect
      {...field}
      {...other}
      error={!!error}
      onBlur={onBlur}
     // helperText={error}
     // InputLabelProps={InputLabelProps || {}}
     >
      <option value=''></option>
      {props.options.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
    </MUINativeSelect>
    {error && <FormHelperText error={true}>{error}</FormHelperText>}
  </FormControl>
}
