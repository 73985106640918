import React from 'react';
import { List, Typography, Paper } from '@material-ui/core';
import {  useValue } from '../data';
import { FuvarListItem } from './FuvarList';


export function NextFuvar(props) {
  const aktivjarat = useValue('aktivjarat');
  const list = useValue('jarat');
  if(aktivjarat || !list) return null;
  const al = list.filter(f=>{
    if(f.lezarva || f.lemondva || !f.sajat) return false;
    
    if(!f.sajattetel) f.sajattetel = f.tetel.filter(t => t.sajat);
    if(!f.sajattetel.length) return false;
    return true;
  });
  if(al.length===0) return null;
  return <Paper style={{position:'fixed',bottom:0,right:0,left:0,textAlign:'center'}}>
  <Typography variant="caption">Következő fuvar</Typography>
  <List dense>    
    <FuvarListItem {...al[0]} /> 
  </List>
</Paper>
}