import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { ResponsiveLayout } from './Responsive';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { useLocation, useHistory } from 'react-router-dom';

const drawerWidth = 450;

const styles = theme => ({
  drawer: {
    position: 'absolute',
    width: drawerWidth,
    top: 0,
    bottom: 0,
    transition: theme.transitions.create('right', {
      duration: theme.transitions.duration.shortest,
    }),
    display: 'flex',
    zIndex: 1200,
    maxWidth: '100vw',
  },
  drawerClosed: {
    right: 0 - drawerWidth,
  },

  drawerOpen: {
    right: 0,
  },
});


export const useSidebar = () => {
  const history = useHistory();
  const location = useLocation();
  let { state } = location;
  let open = null;
  if (state && state.sidebar && state.sidebar) {
    open = state.sidebar.current;
  }
  const setSidebar = useCallback((sb) => {
    if (!sb && location.state.sidebar.cnt) {
      history.go(-location.state.sidebar.cnt);
    }
    if (sb) {
      // eslint-disable-next-line
      if (!state) state = {};
      if (!state.sidebar) state.sidebar = { cnt: 0 };
      state.sidebar.current = sb;
      state.sidebar.cnt++;
      history.push({ ...location, state });
    }
  }, [history, location, open])
  const close = useCallback(() => setSidebar(null), [setSidebar]);
  const reopen = useCallback(() => setSidebar(open), [open, setSidebar]);
  const value = React.useMemo(() => ({ open, close, setSidebar, reopen }), [open, close, setSidebar, reopen]);

  return value;
};


let panels = {};
export function registerPanel({ id, Component, width }) {
  panels[id] = { Component, width };
};

const SidebarDesktop = withStyles(styles)(({ classes, open, children }) => <Paper elevation={6} className={classes.drawer + ' ' + (open ? classes.drawerOpen : classes.drawerClosed)}>{children}</Paper>)
const SidebarMobile = ({ children }) => {
  const { open, close, reopen } = useSidebar();

  return <SwipeableDrawer
    variant="temporary"
    anchor='right'
    open={!!open}
    onOpen={reopen}
    onClose={close}
  >
    {children}
  </SwipeableDrawer>
}


export default (props) => {
  const { open, close } = useSidebar();
  let content = null;
  if (open) {
    props = Object.assign({}, props);
    const id = Object.keys(open)[0];
    const pkey = open[id].id || open[id];
    const { Component, width } = panels[id];
    if (width) props.style = { width }
    content = <Component id={pkey} tabbed close={close} />;
  }

  return <ResponsiveLayout
    renderDesktop={<SidebarDesktop open={open} {...props}>{content}</SidebarDesktop>}
    renderMobile={<SidebarMobile {...props}>{content}</SidebarMobile>}
  />
}