//bekérjük a rendszámot és az eszköz azonosítót, és átváltunk a kódbekérő ablakra
//a kódbekérő ablak elküldi a kódot, a rendszámot és az azonosítót

import React, { useState } from 'react';
import { Button} from '@material-ui/core';
import { fetchJSON } from '../swutils'
import { log, error, startlog, endlog } from '../log';
import { useSave } from '../data';
import { ErrorBoundary } from './ErrorBoundary';
import { TextField } from '../rf/input/Text';
import { Formik, Form } from 'formik';
import InputAdornment from '@material-ui/core/InputAdornment';
import { VisszahivasLink } from '../dlg/VisszahivasDlg';

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export default () => {
  const [rec, setRec] = useState({});
  console.log("rec", rec);

  return <ErrorBoundary component="RegisterDevice">
    {rec.prefix ? <Kod {...rec} /> : <Rendszam setValue={setRec} />}
  </ErrorBoundary>
}

function Rendszam({ setValue }) {
  const onSubmit = async ({ eszkoz, frsz }) => {
    if (eszkoz && frsz) {
      try {
        let { id, prefix } = await fetchJSON('POST', '/tport/registration', { eszkoz, frsz });
        if (prefix && id) {
          setValue({ prefix, eszkoz:id, frsz });
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  return <Formik initialValues={{}} validateOnChange onSubmit={onSubmit}>
    <Form>
      <div style={{ padding: 48, textAlign: 'center' }}>
        <TextField name="frsz" type="text" fullWidth label="Vontató rendszáma" />
        <TextField name="eszkoz" type="number" label="Eszköz azonosító" fullWidth />
        <Button
          style={{ margin: 48 }}
          type="submit"
          variant="contained"
          color="primary">
          Regisztráció
        </Button>
        <VisszahivasLink />
      </div>
    </Form></Formik>
}


function Kod({ prefix, eszkoz, frsz }) {
  const save = useSave('config');
  const [sw, setSW] = useState(false);

  if (navigator.serviceWorker && !sw) {
    navigator.serviceWorker.ready.then((sw) => {
      setSW(sw);
    });
  }

  async function askUserPermission() {
    try {
      startlog("askUserPermission");
      const result = await Notification.requestPermission();
      log("result", result);
      localStorage.setItem('notifications', result);
    } catch (e) {
      error("error", {error:e.stack});
    }
    endlog();
  }


  async function registerForPush(eszkoz) {
    try {
      const vapid = await fetchJSON('GET', '/message/serverKey');
      log('vapid', vapid);
      log('int8array', urlBase64ToUint8Array(vapid));
      const subscription = await sw.pushManager.subscribe({ userVisibleOnly: true, applicationServerKey: urlBase64ToUint8Array(vapid) });
      log('subscription', subscription);
      const body = { eszkoz, subscription: subscription.toJSON() };
      await fetchJSON('POST', '/message/subscribe', body);
      log('ready');
    } catch (e) {
      console.log(e, e.stack);
      error('requestForPush', { error: e.stack });
    }
  }

  const onSubmit = async ({ kod }) => {
    if (kod) {
      try {
        let config = await fetchJSON('POST', '/tport/registration', { eszkoz, frsz, prefix, kod });
        if (config) {
          await askUserPermission();
          await registerForPush(eszkoz);
          save(config);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  return <Formik initialValues={{}} onSubmit={onSubmit}>
    <Form>
      <div style={{ padding: 48, textAlign: 'center' }}>
        <TextField name="kod" type="number" label="6 számjegyű kód"
          InputProps={{
            startAdornment: <InputAdornment position="start">{prefix} - </InputAdornment>,
          }}
        />
        <Button
          style={{ margin: 48 }}
          type="submit"
          variant="contained"
          color="primary">
          Kód küldése
      </Button>
      </div>
    </Form></Formik>
}