import React from 'react';
import { Button, Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Formik, Form } from 'formik';
import { useDispatch, useValue } from '../data';
import { TextField } from '../rf/input/Text';
import { Send, WhatsApp } from '@material-ui/icons';
import { fetchJSON } from '../swutils';
import { useSnackbar } from 'notistack';
import { useDialog } from '../rf/Dialog';
import config from '../config.json';


export default function VisszahivasDlg({ close }) {
  const sofor = useValue('sofor');
  let { enqueueSnackbar } = useSnackbar();

  const validate = ({ tel }) => tel && tel.length > 8 ? {} : { tel: 'milyen számon tudjuk visszahívni?' };

  const onSubmit = async ({ tel, msg }) => {
    let res = await fetchJSON('POST', '/message/visszahivas', { tel, msg });
    if (res === 'OK') {
      enqueueSnackbar('Hamarosan visszahívjuk!', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        }, autoHideDuration: 2000
      })
    } else {
      enqueueSnackbar('Jelenleg nem lehet kérni a [ t d r i v e r ]-en keresztül visszahívást!', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        }, autoHideDuration: 3000
      })
    }
    close();
  }

  return <Formik initialValues={{ tel: (sofor && sofor.tel) || '', msg: '' }} validate={validate} onSubmit={onSubmit}>
    <Form>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh' }}>
        <DialogContent>
          <Typography variant="body2">Technikai segítségre van szüksége a telefonnal vagy a [ t d r i v e r ] alkalmazással kapcsolatban? Jelezze és mi visszahívjuk!</Typography>
          <TextField name="tel" fullWidth autoFocus variant="filled" placeholder="+36301234567" label="Telefonszám" />
          <TextField name="msg" fullWidth variant="filled" multiline rows={4} placeholder="Segít nekünk, ha leírja röviden a problémát." />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} >Mégsem</Button>
          <div style={{ flex: '1 0 0' }} />
          <Button
            endIcon={<Send />}
            type="submit"
            variant="contained"
            color="primary">
            Visszahívást kérek
          </Button>
        </DialogActions>
      </div>
    </Form></Formik>
}

export function VisszahivasLink(){
  const { setDialog } = useDialog();
  if(config && config.visszahivas){
    return <Typography style={{textAlign:'center', padding: 20}} variant="body2" onClick={() => setDialog({ id: 'visszahivas' })}> <WhatsApp /> Segítség kell? Kattints ide...</Typography>
  }

  return null;
}