import React, { useState, useEffect, useMemo } from 'react';
import { Typography, IconButton, Button, LinearProgress, ListItem, ListItemText, ListItemIcon, DialogContent, DialogActions, CircularProgress } from '@material-ui/core';
import { useDispatch, useValue, useAktivJarat } from '../data';
import { ErrorBoundary } from '../app/ErrorBoundary';
import { AssignmentTurnedIn, Assignment, Delete, Close } from '@material-ui/icons';
import { useDialog } from '../rf/Dialog';
import { fetchJSON } from '../swutils';
import { Formik, Form, useFormikContext } from 'formik';
import StarIcon from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import config from '../config.json'
import { TextField2 } from '../rf/input/Text';
import { asc, stampFromMoment } from '../../utils.mjs';

export function ErtekelesDlg({ close }) {
  const [oldal, setOldal] = useState(0);
  const [setup, setSetup] = useState(false);
  const [sending, setSending] = useState(false);
  const [response, setResponse] = useState(false);
  const potkocsi = useValue('potkocsi');
  const jlist = useValue('jarat');
  const aktivjarat = useValue('aktivjarat');
  const pos = useValue('pos');
  const sofor = useValue('sofor');
  const { kocsi } = useValue('config');


  useEffect(() => {
    fetchJSON('GET', '/ertekeles').then(json => {
      console.log("setup", json);
      setSetup(json);
    })
  }, [setSetup])

  let content;
  if (!setup || sending) {
    content = <LinearProgress />
  } else if (response) {
    content = <Typography variant="h6" style={{ textAlign: 'center', marginTop: 64 }}>{response}</Typography>
  } else if (oldal < setup.length) {
    content = <Oldal cim={setup[oldal].cim} kerdes={setup[oldal].kerdes} />
  } else {
    content = <SubmitOldal />
  }

  const onSubmit = async (values) => {
    setSending(true);
    if (!values.jarat) {
      let jarat = aktivjarat;
      if (!jarat) {
        let kesz = jlist.filter(j => j.lezarva);
        if (kesz.length) {
          kesz.sort((a, b) => asc(a.kezdet, b.kezdet));
          jarat = kesz.pop().pos
        }
      }
      if (jarat) values.jarat = jarat.pos;
    }

    values = { sofor: sofor && sofor.rnev, kocsi, potkocsi: potkocsi && potkocsi.frsz, ...values };
    console.log("submit", values);

    let res = await fetchJSON('POST', '/ertekeles', values);
    setResponse(res);
    setSending(false);
    setTimeout(close, 2000);
  }


  return <ErrorBoundary component="ErtekelesWizzard">
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <DialogContent style={{ width: '100%' }}>
        <Formik initialValues={{ valasz: {} }} validateOnChange onSubmit={onSubmit}>
          <Form>
            {content}
          </Form>
        </Formik>
      </DialogContent>
      <DialogActions style={{ width: '100%', justifyContent: 'space-between' }}>
        {oldal === 0 && <Button onClick={close} >Kilép</Button>}
        {setup && !!oldal && <Button onClick={() => setOldal(oldal - 1)} >Vissza</Button>}
        {setup && oldal < setup.length && <Button color="primary" variant="contained" onClick={() => setOldal(oldal + 1)} >Tovább</Button>}
      </DialogActions>
    </div>
  </ErrorBoundary>
}


function Oldal({ cim, kerdes, }) {
  return <div style={{ textAlign: 'center' }}>
    <Typography style={{ textAlign: 'center' }} variant="h6">{cim}</Typography>
    {kerdes.map(k => <Kerdes key={k} txt={k} />)}
  </div>
}

function Kerdes({ txt }) {
  const { values, setFieldValue } = useFormikContext();
  const { valasz } = values;
  return <div>
    <Typography variant="body2" style={{ marginTop: 26, marginBottom: -8 }}>{txt}</Typography>
    <div style={{ display: 'flex',  justifyContent: 'center' }}>
      <IconButton size="small" onClick={() => {
        delete valasz[txt];
        setFieldValue('valasz', valasz);
      }}><Close size="small" /></IconButton>
      {[1,2,3,4,5].map(i => <IconButton key={i} onClick={() => {
        valasz[txt] = i;
        console.log("Válasz",valasz);
        setFieldValue('valasz', valasz);
      }}>
        {valasz[txt] >= i ? <StarIcon style={{ color: config.color.primary }} /> : <StarBorder />}
      </IconButton>)}
    </div>
  </div>
}

function SubmitOldal() {
  const { values } = useFormikContext();
  let enabled = Object.keys(values.valasz).length > 0 || values.memo;
  return <div>
    <TextField2 multiline rows={10} placeholder={`Egyéb észrevételek, javaslatok`} name="memo" />
    <div style={{ textAlign: 'center' }}><Button disabled={!enabled} color="primary" variant="contained" type="submit" >Étékelés küldése</Button></div>
  </div>
}