import React, { useEffect, useState } from 'react';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useWindowDimensions } from '../rf/Responsive';
import { Formik, Form, FastField } from 'formik';
import { useDispatch, useValue } from '../data';
import { useDialog } from '../rf/Dialog';
import { TextField2 } from '../rf/input/Text';
import { GpsFixed } from '@material-ui/icons';

import { KMOraInput, OffsetInput, SulyInput, MennyisegInput, SzallitolevelInput } from './Input';
import { SwitchField } from '../rf/input/Switch';

import moment from 'moment';
import { stampFromMoment, stampToMoment } from '../../utils.mjs';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import config from '../config.json'


export function ErkezesDlg({ open: { t }, close }) {
  const { width, height } = useWindowDimensions();
  const dispatchJarat = useDispatch('jarat');
  const pos = useValue('pos');
  const dispatchPos = useDispatch('pos');
  const [validpos, setValidPos] = useState(false);
  const kellfrisspos = t.jelleg === 2 || t.jelleg === 3;
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(p => {
      const { latitude, longitude, accuracy, speed, heading } = p.coords;
      let timestamp = moment(new Date(p.timestamp)).format('YYYY-MM-DD HH:mm:ss');
      let domts = p.timestamp;
      let val = { coords: { latitude, longitude, accuracy, speed, heading }, timestamp, domts };
      dispatchPos('update', val);
    }, error => {
      console.log(error);
    },
      { maximumAge: 0, enableHighAccuracy: true })
  }, [dispatchPos]);

  useEffect(() => {
    if (!pos || !pos.pos) {
      setValidPos(false);
      return;
    }
    let diff = moment().diff(stampToMoment(pos.pos.timestamp), 's');
    if (diff > 30) {
      setValidPos(false);
      return;
    } else {
      setValidPos(true);
      return;
    }

  }, [pos, setValidPos]);

  const onSubmit = async ({ km, ido, masiksofornek }) => {
    await dispatchJarat('erkezes', { jarat: t.jarat, tetel: t.sorszam, ido, km, masiksofornek });
    close({});
  }

  return <Formik initialValues={{ km: '', offset: 0, ido: stampFromMoment(moment()) }} validateOnChange onSubmit={onSubmit}>
    <Form>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh' }}>
        <DialogContent>
          <Grid container spacing={2} style={{ marginLeft: 'auto', marginRight: 'auto', width: width > height ? '100%' : 316 }}>
            <Grid item xs={12}>
              <OffsetInput label='Érkezés ideje' />
            </Grid>
            <Grid item xs={12}><KMOraInput /></Grid>
            {t.jelleg === 2 && <Grid item xs={12}>
              <TextField2 multiline rows={4} label={`Üzenet az átakasztásra érkező sofőrnek`} name="masiksofornek" />
            </Grid>}
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={close} >Mégsem</Button>
          <div style={{ flex: '1 0 0' }} />
          <Button
            disabled={kellfrisspos && !validpos}
            endIcon={validpos ? <GpsFixed /> : <CircularProgress color="inherit" size={20} />}
            type="submit"
            variant="contained"
            color="primary">
            Érkezés jelentése
          </Button>
        </DialogActions>
      </div>
    </Form></Formik>
}


const vanzar = {
  hu: [{ value: 1, label: 'SBS zár és plombavédő' }, { value: 2, label: 'spanyolzár és plombavédő' }, { value: 3, label: 'lakat és plombavédő' }],
  ro: [{ value: 1, label: 'Blocare SBS și etanșant' }, { value: 2, label: 'Incuietoare spaniola și etanșant' }, { value: 3, label: 'Lacat și etanșant' }]
}

const nincszar = {
  hu: [{ value: -1, label: 'Mivel a pótkocsi kialakítása miatt sem SBS sem spanyolzár sem lakat nem alkalmazható, kizárólag plombavédővel zártam le a pótkocsit.' }],
  ro: [{ value: -1, label: 'Deoarece nici SBS, nici încuietoarea sau lacătul spaniol nu pot fi utilizate datorită designului remorcii, am sigilat remorca doar cu un protector de etanșare.' }]
}

const zaropt = vanzar.hu.concat(nincszar.hu);

const kerdes = {
  hu: 'Kérjük, nyilatkozz, hogyan zártad le a rakteret!',
  ro: 'Va rugam sa raportezi cum ai inchis spatiul de incarcare:'
}
const lezarva = {
  hu: 'Büntetőjogi felelősségem tudatában kijelentem, hogy a rakteret lezártam, az alkalmazott zár',
  ro: 'Declar in cunostinta raspunderii mele penale, ca remorca am inchis, incuietoarea folosita:',
}
/*const nyitva = {
  hu: 'Figyelem, nem tudtam lezárni a rakteret, mert',
  ro: 'Atentie, nu am putut sa inchid remorca, din cauza:',
}*/

function PlombaField() {
  const sofor = useValue('sofor');
  const lang = (sofor && sofor.nyelv === 2) ? 'ro' : 'hu';

  return <FastField name="plomba" type="text">{({ field: { name, value }, form: { errors, values, setFieldValue } }) => {
    let border = errors['plomba'] ? '#f00 1px solid' : false;
    const handleChange = (ev, value) => {
      console.log(value);
      setFieldValue(name, value);
    }
    value = value || 0;
    const bs = { width: '100%', border: '1px solid rgba(255, 255, 255, 0.12)', borderRadius: 4 };
    return <>
      <div>{kerdes[lang]}</div>
      <Grid item xs={12} style={{ textAlign: 'center', background: value > 0 ? '#030' : '#000', border, marginTop: 16, opacity: value >= 0 ? 1 : 0.2 }}>
        {lezarva[lang]}
        <ToggleButtonGroup style={{ display: 'block' }} size="small" exclusive value={value} onChange={handleChange}>
          {vanzar[lang].map(({ value, label }) => <ToggleButton style={bs} key={value} value={value}>{label}</ToggleButton>)}
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center', background: value < 0 ? '#300' : '#000', border, marginTop: 16, opacity: value <= 0 ? 1 : 0.2 }}>
        <ToggleButtonGroup style={{ display: 'block' }} size="small" exclusive value={value} onChange={handleChange}>
          {nincszar[lang].map(({ value, label }) => <ToggleButton style={bs} key={value} value={value}>{label}</ToggleButton>)}
        </ToggleButtonGroup>
      </Grid>
    </>

  }}</FastField>


}


export function IndulasDlg({ open: { t }, close }) {
  const { width, height } = useWindowDimensions();
  const dispatchJarat = useDispatch('jarat');
  let zar = !config.nincszar && t.jelleg === 1 && <PlombaField />;
  let szlevel = (t.tcap && t.jelleg === 1)? <Grid item xs={12}><SzallitolevelInput /></Grid> : null;

  const validate = ({ plomba }) => {
    const ret = {};
    if (zar && !plomba) ret.plomba = "Plomba állapota?";
    return ret;
  }

  const onSubmit = async ({ suly, ido, mennyiseg, plomba, szallitolevel }) => {
    await dispatchJarat('indulas', { jarat: t.jarat, tetel: t.sorszam, ido, suly, mennyiseg, plomba, zaropt, szallitolevel });
    close({});
  }

  return <Formik initialValues={{ suly: '', offset: 0, mennyiseg: '', ido: stampFromMoment(moment()) }} validateOnChange onSubmit={onSubmit} validate={validate}>
    <Form>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh' }}>
        <DialogContent>
          <Grid container spacing={2} style={{ marginLeft: 'auto', marginRight: 'auto', width: width > height ? '100%' : 316 }}>
            <Grid item xs={12}>
              <OffsetInput label='Indulás ideje' />
            </Grid>
            <Grid item xs={12}><SulyInput /></Grid>
            <Grid item xs={12}><MennyisegInput /></Grid>
            {zar}
            {szlevel}
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={close} >Mégsem</Button>
          <div style={{ flex: '1 0 0' }} />
          <Button
            type="submit"
            variant="contained"
            color="primary">
            Indulás jelentése
          </Button>
        </DialogActions>
      </div>
    </Form></Formik>
}




export function MuszakKezdetDlg({ close }) {
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch('piheno');
  const ciklusDispatch = useDispatch('ciklus');
  const ciklus = useValue('ciklus');
  const urlap = useValue('urlap');
  const { setDialog } = useDialog();

  const onSubmit = async ({ ido, vantizenot }) => {
    console.log("Submit müszak", ido);
    await dispatch('end', { ido, vantizenot });
    await ciklusDispatch('vantizenot', vantizenot);
    if (urlap && urlap.jarmu) {
      setDialog({ id: 'jkonyv', jkonyv: urlap.jarmu.map(m => ({ ...m })) });
    } else {
      close({});
    }
  }

  return <Formik initialValues={{ offset: 0, ido: stampFromMoment(moment()), vantizenot: !ciklus || ciklus.vantizenot }} validateOnChange onSubmit={onSubmit}>
    <Form>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh' }}>
        <DialogContent>
          <Grid container spacing={2} style={{ marginLeft: 'auto', marginRight: 'auto', width: width > height ? '100%' : 316 }}>
            <Grid item xs={12}>
              <OffsetInput label='Műszak kezdete' />
            </Grid>
            <Grid item xs={12}>
              <SwitchField name="vantizenot" label="van még 15 órás műszakom" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} >Mégsem</Button>
          <div style={{ flex: '1 0 0' }} />
          <Button
            type="submit"
            variant="contained"
            color="primary">
            Műszak megkezdve
          </Button>
        </DialogActions>
      </div>
    </Form></Formik>
}

