import moment from 'moment';
import deepEqual from 'fast-deep-equal'
import META from './server/meta.mjs'

export const stampToMoment = str => moment(str, ['YYYY.MM.DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss', moment.ISO_8601])
export const dateToMoment = str => str.length === 10 ? moment(str, ['YYYY.MM.DD', 'YYYY-MM-DD']) : stampToMoment(str);
export const dateToStr = m => m ? m.format('YYYY.MM.DD') : null
export const stampToStr = m => m ? m.format('YYYY.MM.DD HH:mm:ss') : null
export const dateToSql = m => m ? m.format('YYYY-MM-DD') : null
export const stampToSql = m => m ? m.format('YYYY-MM-DD HH:mm:ss') : null

export const stampFromMoment = (m) => m.format('YYYY-MM-DD HH:mm:ss');
export const dateFromMoment = (m) => m.format('YYYY-MM-DD');

export const now = () => stampFromMoment(moment());
export const shortcal = {
  sameDay: '[Ma]',
  nextDay: '[Holnap]',
  nextWeek: 'dddd',
  lastDay: '[Tegnap]',
  lastWeek: '[Múlt] dddd',
  sameElse: 'L'
};
export const shortCalendar = m => m.calendar(shortcal);

export const enumString = (table,field,val) => val? (META[table][field].select[val-1] || '') : ''


export const moneyToStr = (sum, currency, digits = 0) => {
  if (!sum) return '';
  currency = currency || '€';
  // if (currency !== 'Ft') digits = 2;
  return Number(sum).toFixed(digits).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') + ' ' + currency;
}

export const format = {
  shortNumber: (digits = 0, nullval = '', prefix = '', postfix = '') => (num) => {
    if (!num) return nullval;
    let si = [{ value: 1, symbol: "" }, { value: 1E3, symbol: "k" }, { value: 1E6, symbol: "M" }, { value: 1E9, symbol: "G" }, { value: 1E12, symbol: "T" }, { value: 1E15, symbol: "P" }, { value: 1E18, symbol: "E" }];
    let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return prefix + (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol + postfix;
  },
  date: (date) => date && dateToStr(dateToMoment(date)),
  stamp: (date) => date && stampToStr(stampToMoment(date)),
  moment: (format) => (val) => val && dateToMoment(val).format(format),
  fromNow: (val) => val && dateToMoment(val).fromNow(),
  toNow: (val) => val && dateToMoment(val).toNow(),
  calendar: (val) => val && dateToMoment(val).calendar(),
  percent: (val) => Math.round(Number(val) * 100) + '%',
  euro: (val) => moneyToStr(val),
}




export var baseDir = '/';

export const delay = duration => new Promise(resolve => setTimeout(resolve, duration));

export const setBaseDir = (dir) => {
  var path = window.location.pathname.split('/');
  baseDir = path.length > 1 && path[1] === dir ? '/' + dir : '/';
  //  console.log("setBaseDir: "+baseDir);
}

export const relativePath = (path) => {
  path = (path.charAt(0) === '/') ? path.substr(1) : path;
  let bd = baseDir;
  if (bd.substr(-1, 1) === '/') bd = bd.slice(0, -1);
  return bd + '/' + path;
}

export const relativeUrl = (path) => {
  return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + relativePath(path);
}

export const http_url = path => path;

let token = false;
export const setToken = (t,remember) => {
  token = t;
  if((remember || !token) && localStorage) localStorage.setItem('client_token', token);
}

export const getToken = () => token || (localStorage && localStorage.getItem('client_token'));

export const buildUrl = (url, p) => {
  let qs = Object.keys(p).map(k => encodeURIComponent(k) + "=" + encodeURIComponent(p[k]));
  if (qs.length > 0) {
    url = url + "?" + qs.join('&');
  }
  return url;
}

export function validCoords(p) {
  if (!p) return false;
  let { plat, plon } = p;
  return plat > 30 && plat < 70 && plon < 40 && plon > -10;
}

export const fetchJSON = async (method, url, body) => {
  console.log("fetchJSON",{method,url,body});
  let q = {};
  if (method === 'GET' && body) {
    q = Object.assign(q, body);
    body = false;
  }
  url = buildUrl(url, q);
  let prefix = '/';
  if (url.substr(0, 2) === '//') {
    prefix = '';
    url = url.substr(1);
  }

  url = prefix + url;

  const response = await fetch(
    http_url(url), {
    method: method || 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Access-Token': token,
    },
    body: body ? JSON.stringify(body) : null
  });
  const ret = await response.json();
  if(response.status === 200){
    return ret;
  }
  throw new Error(ret.error);
}

function arraysEqual(a, b) {
  if (a === b) return true;

  if (a instanceof Array && b instanceof Array) {
    if (a.length !== b.length) return false;
    for (var i = 0; i < a.length; i++)  // assert each element equal
      if (!arraysEqual(a[i], b[i])) return false;
    return true;
  } return false;
}

let cache = {};
export function cachedArray(func, newVal) {
  return cachedResult(func, newVal, arraysEqual);
}

export function cachedResult(func, newVal, comparator = deepEqual) {
  console.log('cachedResult', { func, newVal });
  if (cache[func] && comparator(cache[func], newVal)) {
    console.log('cache hit', func);
    return cache[func];
  }
  console.log('we need to store it');
  cache[func] = newVal;
  return newVal;
}




//const numCollator = new Intl.Collator('hu-HU', { numeric: true, usage: 'sort' });
export const stringCollator = new Intl.Collator('hu-HU', { usage: 'sort' });
export const searchCollator = new Intl.Collator('hu-HU', { usage: 'search', sensitivity: 'base' });

export const ascNum = (a, b) => (a > b ? 1 : a < b ? -1 : 0);
export const descNum = (a, b) => (a > b ? -1 : a < b ? 1 : 0);
export const ascString = (a, b) => stringCollator.compare(a, b);
export const descString = (a, b) => 0 - stringCollator.compare(a, b);
export const asc = (a, b) => (!Number.isNaN(a) && !Number.isNaN(b)) ? ascNum(a, b) : ascString(0, b);
export const desc = (a, b) => 0 - asc(a, b);


let DEFVAL = {};
export const defval = (rec, val) => {
  if (!DEFVAL[rec]) {
    let meta = META[rec];

    if (!meta) return {};
    DEFVAL[rec] = Object.keys(meta).reduce((acc, f) => {
      acc[f] = meta[f].defval;
      return acc;
    }, {});
  }
  let ret = Object.assign({}, DEFVAL[rec], val);
  /*  let fields = Object.keys(ret);
    for (const f of fields) {
      if (val[f]) ret[f] = val[f];  //itt lehetne typecastolni, hogy csak a metának megfelelő értékeket vehessen fel.
    }*/
  return ret;
}

let NULLVAL = {};
export const nullval = rec => {
  if (!NULLVAL[rec]) {
    let meta = META[rec];

    if (!meta) return {};
    NULLVAL[rec] = Object.keys(meta).reduce((acc, f) => {
      acc[f] = meta[f].nullval;
      return acc;
    }, {});
  }
  return Object.assign({}, NULLVAL[rec]);
}


export function normalizeTel(tel) {
  if (tel.startsWith('00')) tel = '+' + tel.substring(2);
  if (tel.startsWith('06')) tel = '+36' + tel.substring(2);
  return tel.replace(/[ /]/g, '');
}
