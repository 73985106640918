import React from 'react';
import { List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction } from '@material-ui/core';
import { useValue } from '../data';
import { stampToMoment, stampFromMoment } from '../../utils.mjs';
import { Beenhere, Block, PlayArrow } from '@material-ui/icons';
import { useDrawer } from '../hooks';
import moment from 'moment';

export function FuvarListItem({ pos, viszonylat, fuvar, tetel, sajat, sajattetel, lemondva }) {
  const { openDrawer } = useDrawer();
  if (!tetel || !tetel.length) return null;
  if (!sajattetel) sajattetel = tetel.filter(t => t.sajat);
  if (!sajattetel.length) sajattetel = tetel;
  if (fuvar && fuvar.mref) viszonylat = fuvar.mref;
  if (!viszonylat) viszonylat = tetel.filter(t => t.jelleg === 1 || t.jelleg === 4).map(t => t.pdisplay).join(' - ');
  let st = stampToMoment(sajattetel[0].eterv); //saját tételeket kellene figyelni?
  let en = stampToMoment(sajattetel[sajattetel.length - 1].eterv);
  let lezarva = sajattetel.filter(t => !t.eteny || (!t.iteny && t.jelleg !== 6)).length === 0;
  let folyamatban = sajattetel.filter(t => t.eteny || t.iteny).length !== 0;

  if (lemondva || !sajat) {
    return <ListItem style={{ textDecoration: 'line-through', background: '#ff000066' }}>
      <ListItemAvatar><div>{st.format('MMM')}<br />{st.format('DD')}</div></ListItemAvatar>
      <ListItemText primary={viszonylat} secondary={`${st.format('dddd HH:mm')} - ${en.format('dddd HH:mm')}`} />
      <ListItemSecondaryAction>
        <Block />
      </ListItemSecondaryAction>
    </ListItem>
  }
  const ures = sajattetel.length && sajattetel[0].mtipus === 2;
  if (ures) viszonylat = `${viszonylat} üres átállás`

  return <div onClick={openDrawer('jarat', { jarat: pos })}><ListItem style={{ opacity: lezarva ? 0.75 : 1 }}>
    <ListItemAvatar><div>{st.format('MMM')}<br />{st.format('DD')}</div></ListItemAvatar>
    <ListItemText primary={viszonylat} secondary={`${st.format('dddd HH:mm')} - ${en.format('dddd HH:mm')}`} />
    <ListItemSecondaryAction>
      {lezarva ? <Beenhere /> : folyamatban ? <PlayArrow /> : null}
    </ListItemSecondaryAction>
  </ListItem>
  </div>
}

export function FuvarList(props) {
  console.log("FuvarList", props);
  let list = useValue('jarat');
  let aktiv = false;
  if (!list) return <div>Nincs feladat</div>;
  let limit = stampFromMoment(moment().subtract(10, 'd'));
  let lemondlimit = stampFromMoment(moment().subtract(3, 'd'));
  list = list.filter(a => {
    if(!a || !a.tetel || !a.tetel.length) return false;
    
    if(a.lemondva || !a.sajat) return a.tetel[0] && a.tetel[0].eterv > lemondlimit;
    return a.tetel[0] && a.tetel[0].eterv > limit;
  })
  list.sort((a, b) => {
    a = a.sajattetel[0] || a.tetel[0];
    b = b.sajattetel[0] || b.tetel[0];
    if (!a) return -1;
    if (!b) return 1;
    if (a.eterv < b.eterv) return 1;
    if (a.eterv > b.eterv) return -1;
    return 0;
  })


  return <List>
    {list.map((j) => {
      if (j.lezarva || aktiv) return <FuvarListItem key={j.pos} {...j} />
      aktiv = j;
      return <FuvarListItem key={j.pos} {...j} />
    })}
  </List>
}

