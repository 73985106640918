import React from 'react';
import {  useWakeLock } from '../hooks';
//import { Fuvar, CimLink, gpsAddr } from '../app/FuvarList';
import { useValue, useAktivJarat } from '../data';
import { SoforStatus } from './SoforStatus';
import Fuvar from '../fuvar/AktivFuvar';
import Piheno from './Piheno';

export default () => {
  useWakeLock();
  const {jarat} = useAktivJarat();
  const p = useValue('piheno');

  if (p) return <Piheno {...p}/>;
  if (jarat) return <Fuvar />;
  return <div style={{
    background: '#000000',
    height: '100%',
  }}>
    <SoforStatus />
  </div>
}