import React from 'react';
import ReactDOM from 'react-dom';
import './client/index.css';
import App from './client/app/index.js';
//import App from './App.js';
import * as serviceWorker from './serviceWorker';
import { log } from './client/log';
import { fetchJSON } from './client/swutils';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: async (registration) => {
    const waitingServiceWorker = registration.waiting
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          console.log("waiting worker activated. reload...");
          window.location.reload()
        }
      });
      await log("Software update");
      await fetchJSON('POST', '/message/softwareupdate');
      console.log("post SKIP_WAITING");
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
      console.log("posted SKIP_WAITING");
    }
  }
});
