import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { List, ListItem, Button, TextField, IconButton, makeStyles, Grid, Link, LinearProgress } from '@material-ui/core';
import { format } from '../../utils.mjs';
import { useValue, useDispatch, syncMsg, useSave } from '../data';
import { Link as LinkIcon, Refresh, Phone, Directions, Map, Send } from '@material-ui/icons';
import config from '../config.json'
import { Incoming, Outgoing } from '../app/MessageList.js';
import { fetchJSON } from '../swutils.js';
import { useSnackbar } from 'notistack';


function useChatroom(jarat) {
  let { enqueueSnackbar } = useSnackbar();
  let chat = useValue('chatroom');
  let dispatch = useDispatch('chatroom');

  const check = useCallback(async () => {
    if (!navigator || !navigator.onLine) {
      return;
    }
    dispatch('check', { jarat });
  }, [dispatch])

  const send = useCallback(async (txt) => {
    if (!navigator || !navigator.onLine) {
      enqueueSnackbar('Nincs internet', {
        variant:'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        }, autoHideDuration: 2000
      })
      return;
    }
    await dispatch('send', { jarat, txt });
  }, [dispatch])

  useEffect(() => {
    check();
    const i = setInterval(check, config.chatroom * 1000)
//    console.log("Starting chatroom checks", jarat);
    return () => {
      clearInterval(i);
//      console.log("Stopped chatroom checks", jarat);
    }
  }, [jarat, check])

  return useMemo(()=>({ list: chat[jarat] || [], send, check }), [chat, send, check]);
}

export function Chatroom({ pos }) {
  const { list, send, check } = useChatroom(pos);
  const end = useRef();
  const view = useRef();
  const sofor = useValue('sofor');
  const [txt, setTxt] = useState('');

  const onChange = useCallback((ev) => setTxt(ev.target.value), [setTxt]);

  useEffect(() => {
    if (end.current && view.current) {
      view.current.parentElement.scrollTop = end.current.offsetTop;
    }
  }, [list]);

  return <List ref={view} style={{ width: '100vw', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
    {list && list.map((msg, i) => msg.sofor === sofor.rnev ? <Outgoing key={msg.sorszam} {...msg} chatroom={true} /> : <Incoming key={msg.sorszam} {...msg} chatroom={true}/>)}
    <ListItem ref={end} style={{ height: 60, display: 'flex' }}>
      <IconButton onClick={check}>
        <Refresh />
      </IconButton>
      <TextField placeholder="Chat üzenet" fullWidth value={txt} onChange={onChange}></TextField>
      <IconButton onClick={async () => {
        await send(txt);
        setTxt('');
      }}>
        <Send />
      </IconButton>
    </ListItem>
  </List >
}