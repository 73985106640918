import React, { useState, useContext, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import MobileStepper from '@material-ui/core/MobileStepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { ResponsiveLayout } from './Responsive';
import { Formik } from 'formik';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

const styles = theme => ({
  root: {
    textAlign: 'center',
    flexGrow: 1,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 16,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },

  buttons: {
    marginTop: 16,
  },

  mobileRoot: {
    textAlign: 'center',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 8,
  },

  mobileContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 16,
    flexGrow: 1,
    //  background:'blue',
    marginBottom: 96,
  },
  bottomSpacer: {
    position: 'fixed',
    bottom: 48,
    height: 48,
    width: '100%',
    background: "url('/images/form_bottom.png')"
  },
  sidebar: {
    width: '100%',
    height: 60,
    textAlign: 'left',
    overflow: 'auto',
    padding: 4
  }
});

export const DesktopWizzard = withStyles(styles)(props => {
  const { steps, content, classes, nonLinear, sidebar } = props;
  const { activeStep, error, setError, handleNext, handleBack } = useWizzard();

  return (
    <div className={classes.root}>
      {sidebar ? <Typography variant='body1' className={classes.sidebar}>{sidebar}</Typography> : null}
      <Stepper nonLinear={nonLinear} activeStep={activeStep}>
        {steps.map((s) => {
          return (
            <Step key={s.label}>
              <StepLabel>{s.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div className={classes.content}>
        {activeStep === steps.length ? (
          <Typography className={classes.instructions}>
            Kész vagyunk, be kellene zárni az ablakot...
          </Typography>
        ) : (
            <>
              {content}
              <Snackbar open={!!error && error.length > 0} autoHideDuration={4000} className={classes.error} onClose={() => setError(false)}>
                <SnackbarContent className={classes.error} message={error} />
              </Snackbar>
              <div className={classes.buttons}>
                {activeStep === 0 ? null : <Button onClick={handleBack} className={classes.button}>Vissza</Button>}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                  type="submit"
                >
                  {activeStep === steps.length - 1 ? (props.keszlabel || 'Kész') : 'Tovább'}
                </Button>
              </div>
            </>
          )}
      </div>
    </div>
  );
})


export const MobileWizzard = withStyles(styles)(props => {
  const { steps, label, content, classes } = props;
  const { activeStep, handleNext, handleBack, error, setError } = useWizzard();

  return (
    <div className={classes.mobileRoot}>
      <Paper square elevation={0} className={classes.header}>
        <Typography>{label}</Typography>
      </Paper>
      <Typography component="div" className={classes.mobileContent}>{content}</Typography>
      <Snackbar open={error} autoHideDuration={2000} className={classes.error} onClose={() => setError(false)}>
        <SnackbarContent className={classes.error} message={error} />
      </Snackbar>

      <div className={classes.bottomSpacer}></div>

      <MobileStepper activeStep={activeStep} steps={steps.length}
        position="bottom"
        nextButton={
          <Button size="small" onClick={handleNext}>
            {activeStep === steps.length - 1 ? (props.keszlabel || 'Kész') : 'Tovább'}
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" type="button" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            Vissza
            </Button>
        }
      />
    </div>
  );
})

const WizzardData = React.createContext({});
export const useDataWizzard = () => useContext(WizzardData);

const WizzardFunctions = React.createContext({});
export const useWizzard = () => useContext(WizzardFunctions);

export const Wizzard = props => {
  const { steps, step, kesz, breakpoint, validateStep, prepareStep } = props;
  const [activeStep, setStep] = useState(step || 0);
  const [error, setError] = useState('');
  const { getData, setData } = useDataWizzard();

  const data = getData ? getData() : false;

  const handleNext = useCallback(async () => {
    if (getData && validateStep) {
      console.log("handleNext, validateStep", validateStep);
      const e = await validateStep(getData(), steps[activeStep].id, setData, activeStep);
      console.log(e);
      setError(e);
      if (e) return;

      if (activeStep === steps.length - 1 && kesz) {
        const ret = await kesz(getData());
        if (ret && ret.error) setError(ret.error);
      } else {
        if (prepareStep) {
          await prepareStep(data, steps[activeStep + 1].id, activeStep + 1);
        }
        setStep(activeStep + 1);
      }
    }
  }, [activeStep, getData, setData, validateStep, setStep, prepareStep, data, kesz, steps]);

  const handleBack = () => {
    setStep(activeStep - 1);
  }

  const wprops = Object.assign({ content: steps[activeStep].content, label: steps[activeStep].label }, props);

  return <WizzardFunctions.Provider value={{ handleNext, handleBack, activeStep, error, setError }}>
    <ResponsiveLayout
      breakpoint={breakpoint}
      renderDesktop={<DesktopWizzard {...wprops} />}
      renderMobile={<MobileWizzard {...wprops} />}
    />
  </WizzardFunctions.Provider>
}




export class DataWizzard extends React.PureComponent {
  getData = () => this.data;
  setData = uj => {
    this.data = Object.assign(this.data, uj);
  }

  constructor(props) {
    super(props);
    this.data = this.props.initialValues || {};
    console.log("datawizz", [this.props, this.data]);
  }

  render() {
    return <WizzardData.Provider value={{ getData: this.getData, setData: this.setData, calculate: this.props.calculate, isLoading: this.isLoading, setLoading: this.setLoading }}>
      <Wizzard {...this.props} />
    </WizzardData.Provider>
  }
}


const fullWidth = { width: '100%' };

export const DataWizzardForm = props => {
  const { getData, setData } = useDataWizzard();
  const { handleNext } = useWizzard();

  return <Formik initialValues={getData()}
    enableReinitialize
    isInitialValid
    validateOnChange={true}
    validateOnBlur={false}
    validate={setData}
  >{({ errors, values }) => (
    <form onSubmit={handleNext} style={fullWidth}>
      <Grid container justify="center" spacing={16}>
        {props.children}
      </Grid>
      <div>
        {JSON.stringify(values.errors)}
      </div>
      <button type="submit" style={{ position: 'fixed', top: -100 }} onClick={(ev) => {
        ev.preventDefault();
        handleNext();
      }}></button>
    </form>
  )}
  </Formik>
}


const groupstyles = {
  root: {
    display: 'flex',
    alignItems: 'stretch',
  },
  frame: {
    border: '1px solid #ccc',
    borderRadius: 3,
    padding: 16,
    paddingTop: 0,
    flexGrow: 1,
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    background: '#fff',
    position: 'relative',
    top: -10,
    padding: '0px 16px',
    display: 'inline',
    alignSelf: 'flex-start'
  }
};
export const FormGroup = withStyles(groupstyles)(props => {
  const { label, classes, children, ...other } = props;
  return <Grid item {...other} className={classes.root}>
    <div className={classes.frame}>
      {label ? <Typography variant="caption" component="span" className={classes.label}>{label}</Typography> : null}
      {children}
    </div>
  </Grid>
})