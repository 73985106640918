import React, { useState, useEffect } from 'react';
import { Button, Fab, LinearProgress } from '@material-ui/core';
import { NotificationsActive, NotificationsNone, NotificationsOff, Sms } from '@material-ui/icons';
import { useDispatch, useValue } from '../data';
import { stampToMoment } from '../../utils.mjs';
import { MessageList } from '../app/MessageList';
import config from '../config.json'
import moment from 'moment';
import { ErrorBoundary } from '../app/ErrorBoundary';
import {useWindowDimensions} from '../rf/Responsive';

export function SOSWindow({ fab }) {
  const {width,height} = useWindowDimensions ();
  const sos = useValue('sos');
  const dispatch = useDispatch('sos');
  const [counter, setCounter] = useState(config.sos.countdown);
  const [refresh, setRefresh] = useState(0);
  const dispatchPos = useDispatch('pos');
  const pos = useValue('pos');
  const sofor = useValue('sofor');
  const {kocsi} = useValue('config');



  useEffect(() => {
    navigator.geolocation.getCurrentPosition((p) => {
      const { latitude, longitude, accuracy, speed, heading } = p.coords;
      let timestamp = moment(new Date(p.timestamp)).format('YYYY-MM-DD HH:mm:ss');
      let domts = p.timestamp;
      let val = { coords: { latitude, longitude, accuracy, speed, heading }, timestamp, domts };
      dispatchPos('update', val);
    },
      error => {
        console.log("positionError", error);
      },
      {
        enableHighAccuracy: true,
        timeout: 0,
        maximumAge: 30000,
      }) // eslint-disable-next-line
  }, []);


  useEffect(() => {
    let t = setTimeout(() => setRefresh(refresh + 1), 20000);
    return () => clearTimeout(t);
  }, [refresh, setRefresh]);

  useEffect(() => {
    if (sos.alerted) {
      setCounter(0);
    } else {
      if (counter) {
        let t = setTimeout(() => setCounter(counter - 1), 1000);
        return () => clearTimeout(t);
      } else {
        dispatch('alert');
      }
    }
  }, [counter, sos, dispatch, setCounter])

  let background;

  let status = null;
  let content;
  if (sos.alerted) {
    if (sos.test) {
      background = '#59e';
      status = <div>
        <h1>Riasztás teszt</h1>
        <div>{stampToMoment(sos.test).fromNow()}</div>
      </div>
    } else {
      background = '#400';
      status = <div>
        <h1>S.O.S</h1>
        <div>{stampToMoment(sos.last).fromNow()}</div>
      </div>
    }

    let smstxt = `${sos.test? 'TESZT':'S.O.S.'} riasztás`;
    if(pos && sofor && kocsi){
      smstxt = `${smstxt}: ${sofor.rnev} ${kocsi} GPS: https://google.com/maps?q=${pos.pos.coords.latitude},${pos.pos.coords.longitude}`;
    }
    smstxt = encodeURI(smstxt);

    content = <>
      <MessageList filter={(m) => m.stamp > sos.start} style={{ flexGrow: 100, overflow: 'auto' }} />
      <Fab style={{ position: 'absolute', bottom: 16, left: 16, background: '#3a1' }} color="primary" onClick={() => dispatch('clear')}><NotificationsOff /></Fab>
      <Fab style={{ position: 'absolute', bottom: 16, right: 90, background: '#e00' }} color="primary" href={`sms:${config.sos.sms}?body=${smstxt}`}><Sms /></Fab>
      {fab}
    </>


  } else {
    background = `rgb(${250 - (counter / config.sos.countdown * 200)},0,0)`

    status = <>
      <div><LinearProgress variant="determinate" value={counter * (100 / config.sos.countdown)} /></div>
      <div style={{ margin: 32, padding: 32, display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 10 }}>
        <div style={{ color: '#ccc' }}>riasztás</div>
        <div style={{ fontSize: 60, margin: 16 }}>{counter} s</div>
        <div style={{ color: '#ccc' }}>múlva</div>
      </div>
    </>

    content = <>
      <Button style={{ margin: 32, padding: 32, background: '#dde', color: '#222' }} variant="outlined" onClick={() => dispatch('clear')} ><NotificationsOff /> MÉGSEM</Button>
      <Button variant="outlined" style={{ margin: 32, padding: 32, background: '#900', color: '#fff' }} onClick={() => dispatch('alert')} ><NotificationsActive />  RIASZTÁS MOST</Button>
      <Button style={{ margin: 32, padding: 16, background: '#59e', color: '#fff' }} onClick={() => dispatch('test')} ><NotificationsNone />  TESZT</Button>
    </>
    if(width > height){
      content = <div style={{display:'flex'}}>{content}</div>
    }
  }
  return <ErrorBoundary component="SOSWindow" top={10}>
    <div style={{
      width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column',
      background, transition: 'background-color 0.5s ease', textAlign: 'center'
    }}>
      {status}
      {content}
    </div>
  </ErrorBoundary>

}