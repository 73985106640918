import React from 'react';
import { Typography } from '@material-ui/core';
import { stampFromMoment, stampToMoment } from '../../utils.mjs';
import { Link } from '../rf/Link';
import { useDialog } from '../rf/Dialog';
import { ErrorOutline, Info, Link as LinkIcon } from '@material-ui/icons';
import config from '../config.json';

export function CimLink({t}){
  if (t.plat && t.plon) {
    if (t.orszag && t.irsz) {
      return <Link addr={`${t.plat},${t.plon}`}>{`${t.orszag} ${t.irsz}, ${t.varos}`} <br /> {t.cim || ''} {t.tcap && t.epulet && <><br/>{t.epulet}</>}</Link>
    } else {
      return <Link addr={`${t.plat},${t.plon}`}>{`${t.plat}, ${t.plon}`}</Link>
    }
  } else if (t.orszag && t.irsz && t.varos && t.cim) {
    return <Link addr={`${t.orszag} ${t.irsz}, ${t.varos} ${t.cim}`}>{`${t.orszag} ${t.irsz}, ${t.varos}`} <br /> {t.cim || ''}</Link>
  }
  return null;
}

export function Celpont({ t, first, last, sajat }) {
  const { setDialog } = useDialog();

  if (t.tipus === 3) return null;
  const st = stampToMoment(t.eterv);
  
  const ptxt = ['', 'Felrakó', 'Átakasztás', 'Átakasztás', 'Lerakó','Tankolás','Via pont'];
  const tipus = ptxt[t.jelleg];
  let map = <CimLink t={t}/>;
  let dir = <Link directions={gpsAddr(t)} />;
  let label = `${tipus} - ${t.epulet || t.prnev || t.pdisplay}`;

  if (t.mtipus === 2 && sajat) {
    label = first? 'Üres indulás' : `Felrakó - ${t.epulet || t.prnev || t.pdisplay}`;
    if(first){
      map=<div><br/></div>;
      dir=null;    
    }
  }
  let en = null;
  if(config.iterv && t.iterv){
    en = stampToMoment(t.iterv).format('HH:mm');
  }

  return <div
    style={{ position: 'relative', opacity: sajat ? 1 : 0.4 }}>
    <Typography variant="body2" style={{ position: 'absolute', top: 0, left: -70, width: 60, textAlign: 'center' }}>
      {st.format('dd MM.DD')}<br />{st.format('HH:mm')}<br/>
      {en}
    </Typography>
    <div style={{ paddingLeft: 16, paddingBottom: 16, borderLeft: last ? '3px solid #424242' : t.jelleg===2? '3px solid #cccccc66' : '3px solid #ccc' , position: 'relative' }} >
      <div style={{ borderRadius: '50%', border: '3px solid #ccc', background: '#424242', position: 'absolute', left: -9, top: 0, width: 16, height: 16 }}>
      </div>
      {label}<br />
      {first? <div style={{display:'flex',justifyContent:'space-between'}}>
      {map} {dir}</div> : map}
      {console.log("info",t.infos)}
      {t.infos? t.infos.map((info,i) => <div key={i} style={{display:'flex'}}>
        <ErrorOutline /><div style={{padding: 8}}>{info}</div>
      </div>) : null}
      {t.soforinfo? <div>{t.soforinfo}</div>: null}
      {t.pontinfo? t.pontinfo.map((info,i) => <div key={i} style={{display:'flex'}}>
        <Info /><div style={{padding: 8}}> 
          {info.memo}
          {info.upload? <Link href={info.upload}><LinkIcon/></Link>: null} 
          <br/><i>{info.sofor} {stampToMoment(info.stamp).fromNow()}</i>
          <div style={{textAlign:'right',color:'#999'}} 
            onClick={()=>setDialog({ id: 'pontinfohiba', rnev:t.prnev, memo:info.memo, pontinfo: info.id})}>
            Ha az infó elavult vagy téves kérjük ide kattintva jelezze
          </div>
        </div>
      </div>) : null}
    </div>
  </div>
}

export function cimAddr(t){  
  if (t.orszag && t.irsz && t.varos && t.cim) return `${t.orszag} ${t.irsz} ${t.varos} ${t.cim}`
  if (t.plat && t.plon) return `${t.plat},${t.plon}`;
  return '';
};

export function gpsAddr(t){  
  if (t.plat && t.plon) return `${t.plat},${t.plon}`;
  if (t.orszag && t.irsz && t.varos && t.cim) return `${t.orszag} ${t.irsz} ${t.varos} ${t.cim}`
  return '';
}


