import React from 'react';
import { error } from '../log';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    console.log("ErrorBoundary ",props);
    this.state = { hasError: false,error:false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log("Error",error);
    return { hasError: true, error };
  }

  componentDidCatch(e, errorInfo) {
    // You can also log the error to an error reporting service
    error(`Error in ${this.props.component}`,{component:this.props.component, error:e.stack});
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div style={{marginTop:50,maxHeight:'100vh',maxWidth:'100vw',overflow:'auto'}}>
        <h1>Hiba {this.props.component}</h1>
        <pre>{this.state.error.stack}</pre>
      </div>;
    }

    return this.props.children;
  }
}
