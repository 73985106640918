import React, { useCallback } from 'react';
import PageIcon from '@material-ui/icons/OpenInNew';
import { useSidebar } from './Sidebar';
import { useIsMobile } from './Responsive';
import { Link as RouterLink } from 'react-router-dom';
import MUILink from '@material-ui/core/Link';
import { normalizeTel } from '../../utils.mjs';
import { Directions } from '@material-ui/icons';

let links = {};

export function registerLink({ id, page, format }) {
  links[id] = { page, format };
};

export const getPageLink = (link) => {
  if(!link) return false;
  try {
    const id = Object.keys(link)[0];
    if(!id || !link[id]){
      console.error("Érvénytelen hivatkozás: ",{link});
      return false;
    }

    const value = link[id].id || link[id];
    const reg = links[id];
    if(reg && !reg.page){
      return false;
    }
    const url = (reg && reg.page) || id;
    return `/${url}/${value}`;
  } catch (e) {
    console.log(e);
    return false;
  }
}

const pgStyle = {
  fontSize: '15px',
  cursor: 'pointer',
  top: 3,
  position: 'relative',
  marginLeft: 4,
  marginRight: 4
}

const sbStyle = {
  cursor: 'pointer',
}


export const PageLink = ({ style, color, ...link }) => {
  const page = getPageLink(link);
  if (useIsMobile()) return null;
  return page ? <MUILink underline="none" className="pagelink" style={{ color }} component={RouterLink} to={page}><PageIcon style={style} /></MUILink> : null
}

const defFormat = (value) => value? value.rnev || value.nev || value.id || value : '';

export const SidebarLink = ({ children, color, style, className, ...link }) => {
  const {setSidebar} = useSidebar();
  const open = useCallback(e => {
    e.stopPropagation();
    setSidebar(link);
  }, [link, setSidebar]);

  if(!link) return null;
  const id = Object.keys(link)[0];
  if(!id) return null;
  const value = link[id];
  const reg = links[id];
  if (!children) {
    if(reg && reg.format){
      children = reg.format(value);
    }else{
      children = defFormat(value);
    }
  }

  return <span className="applink">
    <MUILink component="span"
       onClick={open} 
       style={sbStyle} 
       color={color}>{children}    
    </MUILink>
    <PageLink color={color} style={pgStyle} {...link} />
  </span>
}

export const Link = ({ children, color, mailto, tel, addr, directions, href, to, lat,gps, ...link }) => {
  if (to)   return <MUILink component={RouterLink} to={to} color={color} underline="none">{children}</MUILink>
  if (href) return <MUILink href={href} target="_blank" color={color}>{children || href}</MUILink>
  if (mailto && mailto.address) return <MUILink href={`mailto:${mailto.address}`} color={color}>{children || mailto.name? `${mailto.name} <${mailto.address}>` : mailto.address}</MUILink>
  if (mailto) return <MUILink href={`mailto:${mailto}`} color={color}>{children || mailto}</MUILink>
  if (tel) return <MUILink href={`tel:${normalizeTel(tel)}`} color={color}>{children || tel}</MUILink>

  /*
  if (addr) return <MUILink href={'geo:' + addr} target='_google_maps'>{children || addr}</MUILink>
  if (directions){
    let origin,destination,waypoints;
    if(Array.isArray(directions)){
      if(directions.length>1) origin = directions[0];
      destination = directions[directions.length-1];
      if(directions.length>2){
        waypoints = directions.slice(1,directions.length-2).join('|');
      }
    }else{
      destination = directions;
    }
    let params = ['api=1','travelmode=driving',`destination=${destination}`];
    if(origin) params.push(`origin=${origin}`);
    if(waypoints) params.push(`waypoints=${waypoints}`);
     return <MUILink href={'https://google.com/maps/dir/?'+params.join('&')} target='_google_maps'>{children  || <Directions fontSize="large" color="primary"/>} </MUILink>
  } 
  */    

 if (addr) return <MUILink href={'https://google.com/maps/search/?api=1&query=' + addr+'&z=15'} target='_google_maps'>{children || addr}</MUILink>
 if (directions){
   let origin,destination;
   if(Array.isArray(directions)){
     if(directions.length>1) origin = directions[0];
     destination = directions[directions.length-1];
     if(directions.length>2){
 //      waypoints = directions.slice(1,directions.length-2).join('|');
     }
   }else{
     destination = directions;
   }
   let params = ['api=1',`destination=${destination}`];
   if(origin) params.push(`origin=${origin}`);
//   if(waypoints) params.push(`waypoints=${waypoints}`);
    return <MUILink href={'https://google.com/maps/dir/?'+params.join('&')} target='_google_maps'>{children  || <Directions fontSize="large" color="primary"/>} </MUILink>
 } 
 
  return <SidebarLink color={color} {...link}>{children}</SidebarLink>
}