import React from 'react';
import { Button } from '@material-ui/core';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Formik, Form } from 'formik';
import { useDispatch } from '../data';
import { HofokInput } from './Input';
import { TextField } from '../rf/input/Text';

export function HutesBeDlg({ open: { t }, close, reset }) {
  const dispatch = useDispatch('potkocsi');

  const validate = ({hutoora,hofok}) =>{
    const ret = {};
    if(!hutoora) ret.hutoora = "Az óraállást kötelező megadni";
    if(typeof hofok === 'undefined') ret.hofok = "Adja mega beállított hőfokot";
    return ret;
  }

  const onSubmit = async (value) => {
    await dispatch('hutesbe', value);
    close({});
  }

  return <Formik initialValues={{ }} validate={validate} onSubmit={onSubmit}>
    <Form>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh' }}>
        <DialogContent style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <HofokInput />
          <TextField name="hutoora" inputProps={{ style: { textAlign: 'center', fontSize: 48 } }} type="number" label="Hűtő óraállás" fullWidth />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} >Mégsem</Button>
          <div style={{ flex: '1 0 0' }} />
          <Button
            type="submit"
            variant="contained"
            color="primary">
            Hűtő bekapcsolva
      </Button>
        </DialogActions>
      </div>
    </Form></Formik>
}

export function HutesKiDlg({ open: { t }, close, reset }) {
  const dispatch = useDispatch('potkocsi');

  const validate = ({hutoora}) =>{
    if(!hutoora) return {hutoora: "Az óraállást kötelező megadni"};
    return {};
  }

  const onSubmit = async (value) => {
    await dispatch('huteski', value);
    close({});
  }

  return <Formik initialValues={{}} validate={validate} onSubmit={onSubmit}>
    <Form>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh' }}>
        <DialogContent>
          <TextField name="hutoora" inputProps={{ style: { textAlign: 'center', fontSize: 48 } }} type="number" label="Hűtő óraállás" fullWidth />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} >Mégsem</Button>
          <div style={{ flex: '1 0 0' }} />
          <Button
            type="submit"
            variant="contained"
            color="primary">
            Hűtő kikapcsolva
      </Button>
        </DialogActions>
      </div>
    </Form></Formik>
}