import React from 'react';
import {Button } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Formik, Form } from 'formik';
import { useDispatch } from '../data';
import { TextField } from '../rf/input/Text';
import { Send } from '@material-ui/icons';

export default function SzabadsagDlg({ close }) {
  const dispatch = useDispatch('msg');
  const validate=({msg})=>msg? {} : {msg: 'valamit írni kell'};  
  const onSubmit = async ({ msg }) => {
    dispatch('send', { msg: 'Szabadság igény:\n'+msg, rnev: 'szabadsagigeny' });
    close();
  }

  return <Formik initialValues={{ msg: '' }} validate={validate} onSubmit={onSubmit}>
    <Form>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh' }}>
        <DialogContent>
          <TextField name="msg" fullWidth autoFocus variant="filled" multiline rows={8} placeholder="Írja meg, hogy mikor szeretné kivenni a szabadságát"/>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} >Mégsem</Button>
          <div style={{ flex: '1 0 0' }} />
          <Button
            endIcon = {<Send/>}
            type="submit"
            variant="contained"
            color="primary">
           Szabadság igénylése
      </Button>
        </DialogActions>
      </div>
    </Form></Formik>
}