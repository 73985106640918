import React from 'react'
import MUISwitch from '@material-ui/core/Switch';
import { FastField  } from 'formik'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useDataWizzard } from '../Wizzard';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import IconButton from '@material-ui/core/IconButton';

const renderSwitch = (
  {field,form,calculate,...other}
) => {

  const  handleChange = (ev,value) => {
    console.log(value);
    form.setFieldValue(field.name, value);
  }

  return (
  <FormControlLabel
    label={other.label}
    control = {
      <MUISwitch
        name = {field.name}
        checked = {field.value? true : false}
        onChange = {handleChange}
        color="primary"
        value = {field.name}
        {...other}
    />}
  />
)}

let SwitchField = (props) => {
  return <FastField 
      component={renderSwitch}
      {...props}
    />
}

const renderStar = (
  {field,form,calculate,...other}
) => {

  const  handleChange = (ev) => {
    form.setFieldValue(field.name, !field.value);
    if (calculate) {
      const oldValue = field.value;
      field.value = !field.value;
      calculate({ oldValue, newValue: field.value, field, form })
    }
  }

  return (
    <IconButton onClick={handleChange}>
      {field.value? <StarIcon/> : <StarBorderIcon />}
    </IconButton>
)}

let StarField = (props) => {
  const {calculate} = useDataWizzard();
  return <FastField 
      component={renderStar}
      margin="dense"
      calculate={calculate}
      {...props}
    />
}


export {SwitchField,StarField};