import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, LayersControl, Pane, Polyline,Marker,CircleMarker } from 'react-leaflet';
import config from '../config.json'
import { fetchJSON } from '../swutils.js';
import './map.css';
import { useValue } from '../data';


let mapOptions = {};

export function Map({ children, layers, style, routeId, tetel, ...props }) {
  const [map, setMap] = useState(null);
  let [attr, setAttr] = useState(mapOptions);
  let [roads, setRoads] = useState(false);
  const [orig, setOrig] = useState(false);
  const pos = useValue('pos');


  useEffect(() => {
    if (!mapOptions.attribution) {
      fetchJSON('GET', '/map/options').then(a => {
        mapOptions = a;
        setAttr(mapOptions);
      });
    }
  }, [setAttr]);

  
  const onClick = t => () => {
    if (!map) return;
    if (orig) {
      map.setView(orig.position, orig.zoom, false);
      setOrig(false);
    } else {
      setOrig({ position: map.getCenter(), zoom: map.getZoom() });
      map.setView([t.plat, t.plon], 14, false);
    }
  }

  useEffect(() => {
    if (!map) return;
    let bounds = calcBounds(tetel);
    console.log("bounds:", bounds);
    console.log("pos",pos);
    if (bounds && bounds[0] && bounds[1]) map.fitBounds(bounds, { padding: [30, 30], maxZoom: 11 });
  }, [map, tetel]);  

  let defaults = config.map || {};
  let l = attr.layers;
  if (l && !l.roads) roads = false;

  return <MapContainer
    style={{ height: '100%', width: '100%', ...style }}
    boxZoom={false}
    scrollWheelZoom={true}
    doubleClickZoom={true}
    minZoom={4}
    whenCreated={setMap}    
    {...defaults}
    {...props}
  >
    <LayersControl collapsed={true}>
      <LayersControl.BaseLayer checked name="Alap">
        <TileLayer
          attribution={attr && attr.attribution}
          url="/map/tile/raster/{z}/{x}/{y}"
        />
      </LayersControl.BaseLayer>
      {l && l.satellite && <LayersControl.BaseLayer name="Műhold">
        <TileLayer
          attribution={attr && attr.attribution}
          url="/map/tile/satellite/{z}/{x}/{y}"
          eventHandlers={{
            add: (e) => {
              setRoads(true);
            },
            remove: (e) => {
              setRoads(false);
            }
          }}
        />
      </LayersControl.BaseLayer>}
      {l && l.traffic && <LayersControl.Overlay name="Forgalom" >
        <Pane name="traffic" style={{ zIndex: 402 }}><TileLayer
          url="/map/tile/traffic/{z}/{x}/{y}"
        /></Pane>
      </LayersControl.Overlay>}
      {l && l.restrictions && <LayersControl.Overlay name="Korlátozások" >
        <Pane name="restr" style={{ zIndex: 403 }}><TileLayer
          url="/map/tile/restrictions/{z}/{x}/{y}"
        /></Pane>
      </LayersControl.Overlay>}
      {roads && <LayersControl.Overlay name="Útháló" checked >
        <Pane name="roads" style={{ zIndex: 401 }}><TileLayer
          url="/map/tile/roads/{z}/{x}/{y}"
        /></Pane>
      </LayersControl.Overlay>}      
      {layers}
    </LayersControl>
    {routeId && <RoutePolyline id={routeId}/>}
    {tetel && tetel.filter(validCoords).map(t => <CelpontMarker key={t.id} tetel={t} onClick={onClick} />)}   
    {pos && pos.pos && <CircleMarker center={{ lat: pos.pos.coords.latitude, lng: pos.pos.coords.longitude }} radius={8} pathOptions={{ color: 'red' }} />}
    {children}
  </MapContainer>
}


export function RoutePolyline({ id }) {
  let [polyline, setPolyline] = useState(false);
  useEffect(() => {
    fetchJSON('GET', 'route/' + id).then(json => {
      console.log("route", json);
      setPolyline(json && json.polyline);
    });
  }, [id, setPolyline]);
  if (!polyline) return null;
  return <Polyline
    positions={polyline}
    pathOptions={{
      color: '#00c',
      weight: 5,
      zIndex: 1
    }} />
}


export function CelpontMarker({ tetel, onClick }) {
  const pos = [tetel.plat, tetel.plon];

  if (!validCoords(tetel)) return null;
  return <Marker
    position={pos}
    eventHandlers={{ click: onClick(tetel) }}
  />
}


export function calcBounds(points) {
  if (!points || !points.length) {
    return false;
  }
  return points.reduce((acc, p) => {
    if (!p) return acc;
    let { plat, plon, bbox } = p;

    if (!bbox) {
      if (!validCoords(p)) {
        return acc;
      }
      bbox = [plat, plon, plat, plon];
    }
    if (!acc[0]) {
      acc[0] = [bbox[0], bbox[1]];
    } else {
      acc[0][0] = Math.min(bbox[0], acc[0][0]);
      acc[0][1] = Math.min(bbox[1], acc[0][1]);
    }

    if (!acc[1]) {
      acc[1] = [bbox[2], bbox[3]];
    } else {
      acc[1][0] = Math.max(bbox[2], acc[1][0]);
      acc[1][1] = Math.max(bbox[3], acc[1][1]);
    }
    return acc;
  }, [false, false]);
}


export function validCoords(p) {
  if (!p) return false;
  let { plat, plon } = p;
  return plat > 30 && plat < 70 && plon < 40 && plon > -10;
}

export default Map;