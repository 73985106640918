import React, { useEffect, useCallback, useState } from 'react';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import Map from '../app/Map';

export default ({ close, open }) => {
  const { routeId, tetel } = open;

  return <div style={{ background: '#000', height: '100vh', display: 'flex', flexDirection: 'column' }}>
    <div style={{ flexGrow: 100 }}>
      <Map routeId={routeId} tetel={tetel} />
    </div>
    <div>
      <Button
        style={{ color: '#ccc', width: '100%', background: '#222' }}
        onClick={close}>
        Bezár
      </Button>
    </div>
  </div>
}

