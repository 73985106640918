import React, { useState, useCallback, useEffect } from 'react';
import { Button, Slider, InputLabel, TextField as MUITextField, FormHelperText } from '@material-ui/core';
import { Field } from 'formik';
import { fetchJSON } from '../swutils';
import moment from 'moment';
import { useValue, useSave, useDispatch } from '../data';
import { TextField2, TextField } from '../rf/input/Text';
import { demo } from '../hooks';
import { NativeSelect } from '../rf/input/Select';
import { ChevronLeft, ChevronRight, Help } from '@material-ui/icons';
import { stampFromMoment } from '../../utils.mjs';

const kmHiba = (info) => (val) => {
  val = Number(val);
  if (!val) return 'A km óra állását kötelező megadni!';
  /* if(demo){
     if (info && val < info.min) return 'Az óra állása kevesebb mint ' + info.min + 'km ?';
     if (info && val > info.max) return 'Az óra állása több mint ' + info.max + 'km ?';
   }*/
  return false;
}

export function KMOraInput(props) {
  const info = useValue('pos');
  return <>
    <TextField name="km" validate={!props.reset && kmHiba(info)} inputProps={props.inputProps || { style: { textAlign: 'center', fontSize: 48 } }} type="number" label="Km óra állás" fullWidth {...props} />
    {demo && info && <small>min: {info.min} max: {info.max} legvonal: {info.legvonal} tipp: {info.virtualkm} </small>}
  </>
}


var offsetColors = [
  { pct: -120.0, color: { r: 0xcc, g: 0x33, b: 0x33 } },
  { pct: 0, color: { r: 0x33, g: 0x33, b: 0x33 } },
  { pct: 5, color: { r: 0xbb, g: 0x99, b: 0x33 } }];

var colorForValue = function (clrs, pct) {
  for (var i = 1; i < clrs.length - 1; i++) {
    if (pct < clrs[i].pct) {
      break;
    }
  }
  var lower = clrs[i - 1];
  var upper = clrs[i];
  var range = upper.pct - lower.pct;
  var rangePct = (pct - lower.pct) / range;
  var pctLower = 1 - rangePct;
  var pctUpper = rangePct;
  var color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
  };
  return [color.r, color.g, color.b].join(',');
  // or output as hex if preferred
};


export function OffsetInput(props) {
  const [now, setNow] = useState(moment());

  console.log("NOW:",now.format('HH:mm'));

  return <Field name="offset" type="number">{
    ({ field: { value }, form: { setFieldValue } }) => {
      const bg = colorForValue(offsetColors, value || 0);
      const set = (v) => {
        setFieldValue('offset', v);
        setFieldValue('ido', stampFromMoment(moment(now).add(v, 'm')));
      }
      const reset = () => {
        const n = moment();
        setFieldValue('offset', 0);
        setFieldValue('ido', stampFromMoment(n));
        setNow(n);
      }

      return <div style={{ background: `radial-gradient(ellipse, rgba(${bg},0.6) 0%, rgba(${bg},0) 70%)` }}>
        <InputLabel>{props.label}</InputLabel>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div onClick={() => set(value - 1)}><ChevronLeft fontSize="large" /></div>
          <div onClick={reset} style={{ fontSize: 48, textAlign: 'center', flexGrow: 100 }}>{moment(now).add(value, 'm').format('HH:mm')}</div>
          <div><ChevronRight fontSize="large" onClick={() => set(value + 1)} /></div>
        </div>
        <Slider
          step={1}
          min={-120}
          max={5}
          value={value}
          onChange={(ev, val) => set(val)}
        />
      </div>
    }
  }
  </Field >
}

export function SulyInput(props) {
  const validate = v => {
    v = Number(v || 0);
    if (v < 0) return 'Nem lehet 0-nál kisebb';
    if (v > 27000) return 'Nem lehet 27t-nál több';
    return false;
  }
  return <>
    <TextField name="suly" validate={validate} inputProps={{ style: { textAlign: 'center', fontSize: 48 } }} type="number" label="Rakomány súlya" fullWidth {...props} />
    <small>A súlyt meg lehet adni kg-ban vagy t-ban is. Ha üres a pót hagyja üresen a mezőt.</small>
  </>
}


export function MennyisegInput(props) {
  const validate = v => {
    v = Number(v || 0);
    if (v < 0) return 'Nem lehet 0-nál kisebb';
    return false;
  }
  return <>
    <TextField name="mennyiseg" validate={validate} inputProps={{ style: { textAlign: 'center', fontSize: 48 } }} type="number" label="Paletták száma" fullWidth {...props} />
    <small>Ha üres a pót hagyja üresen a mezőt.</small>
  </>
}

var tempColors = [
  { pct: -30.0, color: { r: 0x33, g: 0x33, b: 0xff } },
  { pct: 10, color: { r: 0xff, g: 0xff, b: 0xff } },
  { pct: 30, color: { r: 0xff, g: 0x33, b: 0x33 } }];

export function HofokInput(props) {
  let { info } = useValue('potkocsi');
  if (props.info) info = props.info;
  const huto = info && info.pottipus === 1;
  if (!huto) return null;

  return <><Field name="hofok" type="number">{({ field: { value }, meta: { error }, form: { setFieldValue } }) => {
    const kikapcs = typeof value === 'undefined';
    const bg = colorForValue(tempColors, value || 0);
    return <div style={{ background: kikapcs ? '' : `radial-gradient(ellipse, rgba(${bg},0.6) 0%, rgba(${bg},0) 70%)` }}>
      <InputLabel>Beállított hőfok</InputLabel>
      <div style={{ fontSize: 48, textAlign: 'center' }}> {kikapcs ? '-' : (value > 0 ? '+' + value : value) + '°C'} </div>
      <Slider
        step={1}
        min={-32}
        max={32}
        value={value}
        onChange={(ev, val) => setFieldValue('hofok', (val < -30 || val > 30) ? undefined : val)}
      />
      {error && <FormHelperText error required>{error}</FormHelperText>}
    </div>
  }
  }
  </Field>
  </>

}


export function PotInfo({ info }) {
  if (!info || !info.frsz) return null;
  const { tipus, ptipus, plomba, kme, kmk, kmh, bme, bmk, bmh, vegzaro, csgatlo, elvedo, pokroc, potnyitas, pothutes } = info;
  const txt = [`${tipus} ${ptipus}`, `Magasság kívül ${kme}m ${kmk ? ` - ${kmk}m` : ''} ${kmh ? ` -${kmh}m` : ''}, belül ${bme}m - ${bmk}m - ${bmh}m`];
  const desc = [];
  if (plomba) desc.push(plomba);
  if (vegzaro) desc.push(`${vegzaro} db végzáró`);
  if (csgatlo) desc.push(csgatlo);
  if (elvedo) desc.push(elvedo);
  if (pokroc) desc.push(pokroc);
  if (potnyitas && potnyitas !== '0') desc.push(`Nyitás figyelés ${potnyitas}`);
  if (pothutes && pothutes !== '0') desc.push(`Hűtés figyelés ${pothutes}`);
  txt.push(desc.join(", "));

  return <small style={{ maxHeight: 60, overflow: 'auto' }}>
    {txt.map((t, i) => <div key={i}>{t}</div>)}
  </small>

}

export function PotkocsiInput(props) {
  const [unkown,setUnkown] = useState({});
  const potlist = useValue('potkocsilist');
  const savePotList = useSave('potkocsilist');

  useEffect(() => {
    if (!potlist) {
      fetchJSON('GET', '/tport/potkocsi/lista').then(res => {
        if (res && !res.error) {
          savePotList(res);
        }
      })
    }
  }, [potlist, savePotList]);

  const validate = async (v) => {
    v = v.toUpperCase().replace(/[^A-Z0-9]/g, '');
    const van = potlist.find(f => f.frsz === v);
    console.log("Van potlistánk", { v, van });
    if (!van) {
      if (v.length > 5) {
        if(unkown[v]) return "Ismeretlen rendszám";
        const res = await fetchJSON('GET', '/tport/potkocsi/' + v);
        if (res && !res.error && res.frsz) {
          potlist.push(res);
          savePotList(potlist);
          if (props.setInfo) props.setInfo(res);
          return;
        } else {
          setUnkown({...unkown, [v]:true});
          return "Ismeretlen rendszám";
        }
      }
      if (props.setInfo) props.setInfo(false);
      return 'Ismeretlen rendszám';
    } else {
      if (props.setInfo) props.setInfo(van);
    }
  };

  return <TextField2
    name="potkocsi"
    label='Felakasztott pótkocsi rendszáma'
    validate={validate}
    inputProps={{ style: { textAlign: 'center', fontSize: 48 } }}
  />
}

export function KartyaInput(props) {
  const trancard = useValue('trancard');
  const [kartya, setKartya] = useState(false);
  const [pingomb, setPingomb] = useState(true);
  const [pinError, setPinError] = useState(false);
  const dispatchMsg = useDispatch('msg');

  const validate = (v) => {
    let c = trancard.find(t => t.id === v);
    console.log("validate", { v, c });
    if (!c) {
      setKartya(false);
      setPingomb(true);
    } else {
      let sz = c.kartyaszam.replace(/[^\dA-Z]/g, '');
      console.log("kartya", { sz, len: sz.length });
      if (sz.length >= 10) {
        sz = sz.replace(/(.{4})/g, '$1 ').trim();
        console.log("megbontva", { sz, len: sz.length });
      }
      setKartya({ id: c.id, tipus: c.tipstr, szam: sz, pin: false });
      setPingomb(true);
    }
  };

  const setKod = useCallback(async (ev) => {
    let kulcskod = ev.target.value;
    if (kulcskod.length === 4) {
      const ret = await fetchJSON('POST', '/tport/pin', { kulcskod, trancard: kartya.id });
      if (ret.error) {
        setPinError(ret.error);
        console.log(ret.error);
      } else {
        setPinError(false);
        setKartya({ ...kartya, pin: ret.pin });
        dispatchMsg('send', { msg: `${kartya.tipus} PIN-t megkaptam`, rnev: 'PIN', ertek: `trancard:${kartya.id}`, rejtett: '1' });
      }
    }
  }, [kartya, dispatchMsg, setKartya, setPinError]);
  return <><Field fullWidth component={NativeSelect} name="card" label="Fizetés" options={
    trancard.map(c => ({ value: c.id, label: c.tipstr }))
  }
    validate={validate}
    {...props}
  />
    {kartya && kartya.szam && <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>{kartya.szam}</div>
      {kartya.pin && <b>PIN: {kartya.pin}</b>}
      {!kartya.pin && pingomb && <Button variant="outlined" startIcon={<Help />} onClick={() => setPingomb(false)}>PIN kód</Button>}
      {!kartya.pin && !pingomb && <MUITextField helperText={pinError} error={!!pinError} autoFocus onChange={setKod} type="number" label="4 számjegyű kulcskód" />}
    </div>}

  </>
}



export function SzallitolevelInput(props) {
  return <TextField name="szallitolevel" inputProps={{ style: { fontSize: 32 } }} label="Szállítólevél száma" fullWidth {...props} />
}