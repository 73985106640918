import React from 'react';
import {Button } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Formik, Form } from 'formik';
import { useDispatch } from '../data';
import {KMOraInput} from './Input';

export function KmDlg({ open: { t }, close, reset }) {
  const dispatchKM = useDispatch('pos');  

  const onSubmit = async ({ km }) => {
    await dispatchKM('set', km);
    close({});
  }

  return <Formik initialValues={{ km: '' }} validateOnChange onSubmit={onSubmit}>
    <Form>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh' }}>
        <DialogContent>
           <KMOraInput reset={reset}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} >Mégsem</Button>
          <div style={{ flex: '1 0 0' }} />
          <Button
            type="submit"
            variant="contained"
            color="primary">
            Km állás jelentése
      </Button>
        </DialogActions>
      </div>
    </Form></Formik>
}


export function ResetKmDlg(props) {  
  return <KmDlg reset={true} {...props} />
}