import React, { useEffect, useCallback, useState } from 'react';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import { useDrawer } from '../hooks';
import { useDispatch, useValue } from '../data';
import { TextField } from '../rf/input/Text';
import { Formik, Form, Field, useFormikContext } from 'formik';
import { NativeSelect } from '../rf/input/Select';
import { JaratField, UploadField } from './UzenetKuldes';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment';
import { stampFromMoment, stampToMoment } from '../../utils.mjs';
import { GpsFixed, Send } from '@material-ui/icons';
import config from '../config.json';

export default ({ close, open }) => {
  const dispatch = useDispatch('msg');
  const pos = useValue('pos');
  const dispatchPos = useDispatch('pos');
  const [validpos, setValidPos] = useState(false);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(p => {
      const { latitude, longitude, accuracy, speed, heading } = p.coords;
      let timestamp = moment(new Date(p.timestamp)).format('YYYY-MM-DD HH:mm:ss');
      let domts = p.timestamp;
      let val = { coords: { latitude, longitude, accuracy, speed, heading }, timestamp, domts };
      dispatchPos('update', val);
    }, error => {
      console.log(error);
    },
      { maximumAge: 0, enableHighAccuracy: true })
  }, [dispatchPos]);

  useEffect(() => {
    if (!pos || !pos.pos) {
      setValidPos(false);
      return;
    }
    let diff = moment().diff(stampToMoment(pos.pos.timestamp), 's');
    if (diff > 30) {
      setValidPos(false);
      return;
    } else {
      setValidPos(true);
      return;
    }

  }, [pos, setValidPos]);

  const send = (msg) => {
    dispatch('send', { msg, rnev: 'tapa' });
    close();
  }

  return <div style={{ background: '#000', height: '100vh', padding: 24, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'stretch' }}>
    {config.tapa.message && config.tapa.message.map(msg => (
      <div key={msg}>
        <Button
          variant="contained"
          style={{ color: '#fff', width: '100%', padding: 12, background: '#444' }}
          endIcon={validpos ? <Send /> : <CircularProgress color="inherit" size={20} />}
          onClick={() => send(msg)}>
          {msg}
        </Button>
      </div>
    ))}

    <div>
      <Button
        style={{ color: '#ccc', width: '100%', background: '#222' }}
        onClick={close}>
        Mégsem
      </Button>
    </div>
  </div>
}