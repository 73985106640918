import React, { useMemo, useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Dialog as MUIDialog, DialogActions, DialogContent } from '@material-ui/core/';
import { useIsMobile } from './Responsive';

let dialogs = {};
export function registerDialog({ id, op, Component, fullScreen, size }) {
  const dlg = { Component, fullScreen, size };
  if(op){
    dialogs[id + '.' + op] = dlg;
  }else{
    dialogs[id] = dlg;
  }
};

const NotFound = ({ open, cancel }) => {
  if (!open) return null;
  return <><DialogContent>
    <h3 onClick={cancel}>Nincs ilyen ablak: {open.id}.{open.op}</h3>
  </DialogContent>
    <DialogActions>
      <Button onClick={cancel}>Kilép</Button>
    </DialogActions>
  </>
}

function getContent(props) {
  if (!props || !props.open) return { Component: NotFound };
  const { id, op } = props.open;
  return dialogs[id + '.' + op] || dialogs[id] || { Component: NotFound };
}


export const useDialog = () => {
  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  let open = null;
  if (state && state.dialog) {
    open = state.dialog;
  }
  const setDialog = useCallback((dlg, waiting, redirect) => {
    console.log("setDialog:",dlg);
    if (!location.state) location.state = {};
    location.state.dialog = dlg;
    location.state.waiting = waiting;
    if (redirect) {
      console.log("redirecting to:",{location,redirect});
      location.state.redirect = redirect;
    }
    if(!dlg && location.state.redirect){
      history.replace(location.state.redirect);
    }else{
      history.replace(location);
    }
  }, [history, location])
  
  const [canceled, setCanceled] = useState(false);
  const close = useCallback((opt) => {
    let { waiting } = state;
    if(opt && opt.next) waiting = waiting? waiting.concat(opt.next) : opt.next;
    if (waiting && waiting.length) {
      const next = waiting.shift();
      if(next) setDialog(next, waiting, opt.redirect);
    } else {
      setDialog(null, false, opt && opt.redirect);
    }
  }, [state,setDialog]);

  const cancel = useCallback((opt) => {
    setCanceled(true);
    close(opt);
  }, [close, setCanceled]);

  console.log("open",open);
  return useMemo(() => ({ open, setDialog, close, cancel, canceled }), [open, setDialog, close, cancel, canceled]);
};



export function Dialog() {
  let dlg = useDialog();
  let isMobile = useIsMobile();
  let { Component, fullScreen, size } = getContent(dlg);
  return <MUIDialog
    open={!!dlg.open}
    fullScreen={isMobile || !!fullScreen}
    fullWidth
    maxWidth={size || 'sm'}
    onClose={dlg.cancel}
    disableBackdropClick={true}
  >
    <Component {...dlg} />
  </MUIDialog>
}