import React, { useState, useEffect } from 'react';
import { useAktivJarat} from '../data';
import { Typography, Button } from '@material-ui/core';
import moment from 'moment';
import { AccessAlarm } from '@material-ui/icons';
import { stampToMoment } from '../../utils.mjs';
import { NextFuvar } from '../fuvar/NextFuvar';
import AktivFuvar from '../fuvar/AktivFuvar';
import { useDialog } from '../rf/Dialog';

function Ora() {
  const [ido, setIdo] = useState(new Date());
  useEffect(() => {
    const i = setInterval(() => {
      setIdo(new Date())
    }, 500);
    return () => clearInterval(i);
  }, [])
  const most = moment().format('HH mm');
  return <Typography variant="h3" style={{ position: 'relative' }}>
    {most}
    <div style={{
      position: 'absolute',
      'visibility': Math.floor(ido.getTime() / 1000) % 2 ? 'hidden' : 'visible',
      left: '48%', top: -4,
    }}>:</div>
  </Typography>

}


function Countdown({ v }) {
  const [, setIdo] = useState(new Date());
  useEffect(() => {
    const i = setInterval(() => {
      setIdo(new Date())
    }, 1000);
    return () => clearInterval(i);
  }, [])
  return <Typography variant='h6'>
    <AccessAlarm /> {v.fromNow()}
  </Typography>
}


export default (props) => {
  const { jarat } = useAktivJarat();
  const { setDialog } = useDialog();

  const { ora, kezdet } = props;
  const k = stampToMoment(kezdet);
  const v = stampToMoment(kezdet);
  v.add(ora, 'h');


  const panelstyle = {
    background: '#000000',
    height: '100%',
    padding: 10,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  };

  const vege = ()=>{
    setDialog({id:'muszakkezdet'});

  /*  dispatch('end');
    if(urlap && urlap.jarmu){
      setDialog({id:'jkonyv', jkonyv: urlap.jarmu.map(m => ({...m}))});
    }*/
  }


  if (jarat) {
    return <div style={panelstyle}>
      <div style={{padding:16}}>
        <Typography variant='h6'>{ora}h pihenő <br/>{k.format('HH:mm')} - {v.format('HH:mm')}</Typography>
        <Button variant="contained" color="primary" onClick={vege}>Műszak kezdete</Button>
      </div>
      <div style={{ overflowY: 'auto', borderTop: '1px solid #999' }}  ><AktivFuvar /></div>
    </div>
  } else {
    return <div style={panelstyle}>
      <div>
        <Typography variant='h2'>{ora}h pihenő</Typography>
        <Typography variant='h6'>{k.format('HH:mm')} - {v.format('HH:mm')}</Typography>
        <Countdown {...props} v={v} />
        <Button variant="contained" color="primary" onClick={vege}>Műszak kezdete</Button>
      </div>
      <Ora {...props} v={v} />
      <NextFuvar />
    </div>
  }
}