import React from 'react'
import MUITextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { FastField,Field  } from 'formik'


export function TextInput(props) {
  let { field, form, classes, calculate, ...other } = props;
  if (classes) other.InputProps = { ...other.InputProps, classes };
  if (!field.value) field.value = '';
//  console.log("TextInput",props);

  const onBlur = (ev) => {
//    ev.stopPropagation();
//    console.log("blur",ev);
    field.onBlur(ev); //tovább adjuk
    if (calculate) calculate(field.name,ev.target.value);
  } 
  const error = form.errors[field.name];

  return (
    <MUITextField
      {...field}
      {...other}
      margin="normal"
      error={!!error}
      onBlur = {onBlur}
      helperText={error || other.helperText}
      InputLabelProps={other.InputLabelProps || {}}
    />
  );
}


export function MultilineInput(props) {
  let { field, form, classes, calculate, ...other } = props;
  if (classes) other.InputProps = { ...other.InputProps, classes };
  if (!field.value) field.value = '';
  console.log("TextInput",props);

  const onBlur = (ev) => {
//    ev.stopPropagation();
    console.log("blur",ev);
    field.onBlur(ev); //tovább adjuk
    if (calculate) calculate(field.name,ev.target.value);
  } 
  const error = form.errors[field.name];

  return (
    <TextareaAutosize
      {...field}
      {...other}
      error={!!error}
      onBlur = {onBlur}
      helperText={error}
      InputLabelProps={other.InputLabelProps || {}}
    />
  );
}

export const TextField = (props) => {
  return <FastField 
    fullWidth    
    component={TextInput}
    {...props}

  />}

  export const TextField2 = (props) => {
    return <Field 
      fullWidth    
      component={TextInput}
      {...props}
  
    />}