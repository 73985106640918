import React, { useState, useEffect } from 'react';
import { Loading } from '../app/Loading';
import { fetchJSON } from '../swutils';
import config from '../config.json';

export function GParkJKDlg({ open, close }) {
  const [token, setToken] = useState(false);
  const { kocsi, potkocsi, sofor} = open;

  useEffect(() => {
    fetchJSON('POST', '/gparktoken', { kocsi, potkocsi, sofor: sofor.rnev, nev: sofor.nev }).then(setToken);
    const handler = (ev) => {
      console.log('ev', ev)

      if (!ev.data || typeof ev.data !== 'object') return
      if (ev.data.type !== 'gpark') return
      if (ev.data.message === 'close') {
        close();
      }
    }

    window.addEventListener('message', handler)

    // Don't forget to remove addEventListener
    return () => window.removeEventListener('message', handler)
  }, [close, kocsi, potkocsi, sofor])

  if (!token) {
    return <Loading />
  }
  return <iframe
    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', border: 0 }}
    src={`${config.gpark}/${token}`}
  />
}

export function GParkGomb() {

}