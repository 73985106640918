import React, { useState } from 'react';
import { Grid, Button, Typography, IconButton } from '@material-ui/core';
import { AddAPhoto, Send, Delete } from '@material-ui/icons';
import { useDispatch } from '../data';
import { TextField } from '../rf/input/Text';
import { Formik, Form } from 'formik';
import { ToggleField } from '../rf/input/Toggle';
import { useDialog } from '../rf/Dialog';
import imageCompression from 'browser-image-compression';
import { SwitchField } from '../rf/input/Switch';

function Preview({ url, remove }) {
  return <Grid item xs={6} sm={4} md={3} style={{
    height: 150,
    backgroundSize: 'contain',
    backgroundImage: `url(${url})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    textAlign: 'right',
  }}>
    <IconButton fontSize='large' onClick={remove}>
      <Delete />
    </IconButton>
  </Grid>
}

function UploadButton({ onFileUpload, odd }) {
  return <Grid item xs={odd ? 6 : 12} sm={4} md={3} style={{ height: 150 }}>
    <label htmlFor="upload">
      <input style={{ display: 'none' }}
        id="upload"
        name="upload"
        accept="image/x-png,image/jpeg,image/gif"
        type="file"
        onChange={onFileUpload}
        multiple
      />
      <Typography variant="subtitle1" style={{
        width: '100%', padding: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}>

        <div style={{ opacity: 0.5 }}>
          <AddAPhoto /><br /> Ide kattintson fotók készítéséhez vagy kiválasztásához,
        </div>
      </Typography>
    </label>

  </Grid>
}

function UploadField({ file }) {
  const [preview, setPreview] = useState([]);

  const onFileUpload = async (event) => {
    let f = event.target.files;
    if (!f || !f.length) return;
    for (let i = 0; i < f.length; i++) {
//      console.log("file " + i, f[i]);
      //preview.push(URL.createObjectURL(f[i]));
     // file.push(f[i]);
      const { name, type, lastModified } = f[i];
      const cf = await imageCompression(f[i], {maxSizeMB: 0.2});
      preview.push(URL.createObjectURL(cf));
      file.push(new File([cf], name, { type, lastModified }));
    }
    setPreview([...preview]);
  }

  const remove = (i) => {
    console.log("remove", { preview, i });
    preview.splice(i, 1);
    console.log("removed", { preview, i });
    file.splice(i, 1);
    setPreview([...preview]);
  }

  return <Grid container spacing={2}>
    {preview && preview.map((url, i) => <Preview url={url} key={i} remove={() => remove(i)} />)}
    <UploadButton onFileUpload={onFileUpload} odd={preview.length % 2} />
  </Grid>
}

export default ({ close }) => {
  const { open } = useDialog();
  const dispatch = useDispatch('msg');
  const [file] = useState([]);
  const { frsz } = open;

  const onSubmit = ({ leiras, tipus, megoldva }) => {
    leiras = leiras || '';
    let txt = `${megoldva? 'Megoldott ': ''}${tipus || ''} ${frsz}: ${leiras.substr(0, 30)}${leiras.length > 30 ? '...' : ''}`;
    let rnev = 'karjelentes';
    let ertek = `tipus:${tipus}\nfrsz:${frsz}\nmegoldva:${megoldva}\nleiras:${leiras}`;

    console.log("submit:", { txt, rnev, ertek, file });
    dispatch('send', { msg: txt, rnev, ertek, file: file.length? file : false });
    close();
  }

  return <Formik initialValues={{tipus:'Műszaki hiba'}} enableReinitialize validateOnChange onSubmit={onSubmit}>{
    ({ isSubmitting }) => <Form>
      <div style={{
        height: '100vh',
        position: 'relative',
      }}>
        <div style={{ padding: 8, textAlign: "center" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <ToggleField name="tipus" exclusive options={[
                { value: 'Műszaki hiba', label: 'Műszaki hiba' },
                { value: 'Sérülés', label: 'Sérülés' },
                { value: 'Hiány', label: 'Hiány' },
              ]}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField name="leiras" fullWidth autoFocus variant="filled" multiline rows={8} placeholder="Részletes leírás" />
            </Grid>
            <Grid item xs={12}>
              <SwitchField name="megoldva" label="A problémát megoldottam" />           
            </Grid>

            <Grid item xs={12} style={{ flexGrow: 100 }}>
              <UploadField file={file} />
            </Grid>
          </Grid>

          <div disabled={isSubmitting} style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'center', margin: 16 }}>
            <Button onClick={(e) => {
              close();
            }}>Mégsem</Button>

            <Button disabled={isSubmitting} type="submit" color="primary" variant="contained" startIcon={<Send />} onClick={async (e) => {
            }}>Jelentés küldése</Button>
          </div>
        </div>
      </div >
    </Form>
  }</Formik>
}