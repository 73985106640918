import { get } from 'idb-keyval';
import {fetchJSON} from './swutils';
let func = [];

const ERROR = 1;
const WARN = 2;
const INFO = 3;
const DEBUG = 4;

export const log = async (msg, data, level = DEBUG) => {
  console.log(msg, data);
  const config = await get('config');
  if (config && config.naplozas && config.naplozas >= level) {
    await fetchJSON('POST','/log', { level, msg, data, func: func.length ? func[0] : ''})
  }
}

export const error = (msg, data) => log(msg, data, ERROR);
export const warn = (msg, data) => log(msg, data, WARN);
export const info = (msg, data) => log(msg, data, INFO);
export const mindig = async (msg, data, level = INFO) => {
  await fetchJSON('POST','/log', { level, msg, data, func: func.length ? func[0] : ''})
}


export const startlog = (f, data) => {
  func.push(f);
  return log('called', { f, data });
}

export const endlog = () => func.pop();
